import LoginPage from "../authentication/auth/LoginPage";
import Home from "../component/home/Home";
import MyProfile from "../pages/MyProfile";
import NotFound from "../pages/NotFound";
import OrderList from "../pages/OrderList";
import ServiceDetails from "../pages/ServiceDetails";
import ViewCart from "../pages/ViewCart";
import Terms from "../pages/Terms";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import ReturnAndRefundPolicy from "../pages/ReturnAndRefundPolicy";


// Main routes object with child routes
const Allroutes = [
  {
    path: "/home",
    element: <Home />,
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/service-details/:id",
    element: <ServiceDetails />,
  },
  {
    path: "/view-Cart",
    element: <ViewCart />,
  },

  {
    path: "/my-booking",
    element: <OrderList />,
  },
  {
    path: "/my-profile",
    element: <MyProfile />,
  },
  {
    path: "/terms",
    element: <Terms/>,
  },
  {
    path: "/privacy",
    element: <PrivacyPolicy/>,
  },
  {
    path: "/refund",
    element: <ReturnAndRefundPolicy/>,
  },
  // { path: "*", element: <NotFound /> },
];

export default Allroutes;