import { Badge, Button, Card, List, Popconfirm, Skeleton, Space, Tag, message, Spin, Image, Modal, Form, Input, Checkbox } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import "../style/Style.css";
import OrderViewDetails from './OrderViewDetails';
import MyOrderFilter from './MyOrderFilter';
import { CancelOrder, FetchAllOrderList } from '../service/APIService';
import { useAuth } from '../authentication/context/screenContext';
import { AiOutlineCloseCircle } from "react-icons/ai";
import MobileBottomNavigationBar from './MobileBottomNavigationBar';

function OrderList() {
    const { token, logout } = useAuth();
    const [initLoading, setInitLoading] = useState(true);
    const [orderData, setOrderData] = useState([]);
    const [current, setCurrent] = useState(1);
    const [filterStatus, setFilterStatus] = useState(null);
    const [hasMore, setHasMore] = useState(true); // Initialize hasMore state
    const [isLoading, setIsLoading] = useState(false); // Track loading state
    const observer = useRef();
    const lastProductElementRef = useRef();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [formData, setFormData] = useState({
        textField: "",
        checkboxItems: {
        },
    });
    const [bookId,setBookId] = useState("");

    const reasonArray = [
        "Better Deals Elsewhere",
        "Service Too Expensive",
        "Long Wait Times",
        "Inconvenient Location or Hours",
        "Personalized Services Elsewhere",
        "Low-Quality Products",
    ]

    const showModal = (id) => {
        setBookId(id)
        setIsModalVisible(true);
    };

    const handleOk = () => {
        // Handle the form submission or any actions when the OK button is clicked
        let finalArray = [];
        Object.keys(formData.checkboxItems).map(item => {
            if (formData.checkboxItems[item] == true) {
                console.log(item)
                finalArray.push(item)
            }
        })
        if (formData.textField != "") {
            finalArray.push(formData.textField)
        }
        console.log("Form data submitted:", finalArray);
        cancelOrders(bookId,finalArray)
        setFormData({
            textField: "",
            checkboxItems: {
            },
        })
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleCheckboxChange = (e) => {
        setFormData({
            ...formData,
            checkboxItems: {
                ...formData.checkboxItems,
                [e.target.name]: e.target.checked,
            },
        });
    };

    const handleTextFieldChange = (e) => {
        setFormData({
            ...formData,
            textField: e.target.value,
        });
    };



    const ShowOrderList = async () => {
        setIsLoading(true);
        try {
            const res = await FetchAllOrderList(token, current, filterStatus);
            console.log("order list", res);
            if (res.status === 200) {
                // If applying a filter, reset the list data
                if (current === 1) {
                    setOrderData(res.data.data);
                } else {
                    setOrderData(prev => [...prev, ...res.data.data]); // Append new data
                }
                setHasMore(res.data.data.length > 0);
                setInitLoading(false);
            } else if (res.data.code === 283) {
                message.error(res.data.message);
                logout();
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        // Reset the current page and fetch data when the filter status changes
        setCurrent(1);
        ShowOrderList();
    }, [filterStatus]);

    // useEffect(() => {
    //     ShowOrderList();
    // }, [current]);

    useEffect(() => {
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore && !isLoading) {
                // setCurrent(prevPage => prevPage + 1);
            }
        }, { threshold: 1.0 });

        if (lastProductElementRef.current) {
            observer.current.observe(lastProductElementRef.current);
        }

        // Clean up observer on component unmount
        return () => {
            if (observer.current) observer.current.disconnect();
        };
    }, [isLoading, hasMore]);

    const getStatusColor = (status) => {
        switch (status) {
            case 'ONGOING':
                return '#5AB2FF';
            case 'CANCELLED':
                return '#EE4E4E';
            case 'NOTPICKED':
                return '#FFAF61';
            case 'UPCOMING':
                return '#5AB2FF';
            case 'COMPLETED':
                return '#ACD793';
            default:
                return '#B4B4B8';
        }
    };

    const cancel = () => {
        message.error("You have entered no");
    };

    const cancelOrders = async (id,data) => {
        try {
            let body = { userOrderId: id, cancelRemark: data };
            const res = await CancelOrder(body, token);
            console.log("order cancel ", res);
            if (res.status === 201) {
                message.success(res.data.message);
                ShowOrderList(); // Refetch orders after cancellation
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            <div className='container mt-3'>
                <Modal
                    className='cancel-modal'
                    title={<><h6>Why do you want to cancel?</h6><hr></hr></>}
                    visible={isModalVisible}
                    onOk={handleOk}
                    onCancel={handleCancel}
                >
                    <Form>
                        <Form.Item label={<strong>Cancel reasons</strong>}>
                            {
                                reasonArray.map(item => {
                                    return <><Checkbox
                                        name={item}
                                        checked={formData.checkboxItems[item]}
                                        onChange={handleCheckboxChange}
                                    >
                                        {item}
                                    </Checkbox>
                                        <br />
                                    </>
                                })
                            }



                        </Form.Item>
                        <Form.Item label="">
                            <Input
                                value={formData.textField}
                                onChange={handleTextFieldChange}
                                placeholder="Other Reason"
                            />
                        </Form.Item>
                    </Form>
                </Modal>

                <Card size='small' title={<div className='my_order_book'>
                    <p>My Booking</p>
                    <Space>
                        <MyOrderFilter setFilterStatus={setFilterStatus} />
                        {filterStatus != null ?
                            <AiOutlineCloseCircle className='close_icons' onClick={() => setFilterStatus(null)} /> : null}
                    </Space>
                </div>}
                    bordered={false}>
                    <div className="booking_list">
                        <List
                            className="demo-loadmore-list"
                            loading={initLoading}
                            itemLayout="horizontal"
                            dataSource={orderData}
                            renderItem={(item) => (
                                <List.Item
                                    actions={[
                                        <Tag key={item._id} color={getStatusColor(item.status)}>{item.status}</Tag>,
                                        <OrderViewDetails ShowOrderList={ShowOrderList} orderId={item._id} />,
                                        item.status !== "COMPLETED" && item.status !== "ONGOING" && item.status !== "CANCELLED" ? (
                                            // <Popconfirm
                                            //     title="Cancel the Order"
                                            //     description="Are you sure you want to cancel this order?"
                                            //     onConfirm={() => cancelOrders(item._id)}
                                            //     onCancel={cancel}
                                            //     okText="Yes"
                                            //     cancelText="No"
                                            // >
                                            <Button type='link' danger onClick={() => showModal(item._id)}>Cancel</Button>
                                            // </Popconfirm>
                                        ) : <Button type='link' danger disabled>Cancel</Button>
                                    ]}
                                >
                                    <List.Item.Meta
                                        avatar={<img src={item.serviceImage} className='order_image' />}
                                        title={<div>
                                            <p>{item.serviceName}</p>
                                            <p>{item.name}</p>
                                        </div>}
                                        description={<div>
                                            <p>Slot: {item.serviceDateTime}</p>
                                            {item.status !== "PENDING" && item.status !== "NOTPICKED" ? (
                                                <details>
                                                    <summary>Assigned Vendor</summary>
                                                    <p>{item.vendorName}</p>
                                                    <p>{item.mobileNo}</p>
                                                    <Image src={item.profilePic} width={50} height={50} style={{ borderRadius: "8px" }} />
                                                </details>
                                            ) : null}
                                        </div>}
                                    />
                                    <Space>
                                        <b>₹ {item.userPayableAmount}</b>
                                        <span>
                                            <Badge
                                                className="site-badge-count-109"
                                                count={item.totalServices}
                                                style={{ backgroundColor: '#52c41a' }}
                                            />
                                        </span>
                                    </Space>
                                </List.Item>
                            )}
                        />
                        <div ref={lastProductElementRef}></div>
                        {isLoading && <div className='text-center'><Spin /></div>}
                    </div>
                </Card>

            </div>
            {window.innerWidth < 801 && <MobileBottomNavigationBar id={0} />}

        </>
    );
}

export default OrderList;
