// store.js
import { configureStore } from '@reduxjs/toolkit';
import CartSlice from './CartSlice';
import OrderSlice from './OrderSlice';


const store = configureStore({
  reducer: {
    cart: CartSlice,
    orders: OrderSlice
    // Add other reducers here if needed
  },
});

export default store;
