import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Select, Card, message } from 'antd';
import { FetchCategoryList,addNewEnquiry } from '../service/APIService';
import { useAuth } from '../authentication/context/screenContext';



const { Option } = Select;

const EnquiryForm = () => {
  const { logout } = useAuth()
  const [categoryList, setCategoryList] = useState([])

  const showCategoryList = async () => {

    try {
        await FetchCategoryList()
            .then((res) => {
                console.log(" category list", res);
                if (res.status == 200) {
                    setCategoryList(res.data.data);
                  
                } else if (res.data.code == 283) {
                    message.error(res.data.message)
                    logout()
                }
            })
            .catch((err) => {
                message.error(err.message);
            });
    } catch (error) {
        console.log(error);
       
    }
};

useEffect(() => {
    showCategoryList()
}, [])
  console.log("categoryList",categoryList);
  const onFinish = async (values) => {
    try{
         await addNewEnquiry(values).then((res)=>{
          if(res.status == 200){
            message.success(res.data.message)
          }
         }).catch((err)=>{
          message.error(err.message);
         })
    } catch (err){
      console.log(err);
    }
  
    console.log('Received values: ', values);
  };

  const onFinishFailed = (errorInfo) => {
    message.error('Please complete the required fields!');
    console.log('Failed:', errorInfo);
  };

  return (
    <div className="enquiry-form-container">
      <Card
        title="Enquire Now "
        bordered={false}
        className="enquiry-card"
        headStyle={{ textAlign: 'center', padding: '2px 0' }}
        // style={{padding:"0px",margin:"0px"}}
      >
        <div className="form-content">
          <Form
            name="enquiry-form"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
            // initialValues={{ service: 'Gel Polish Natural Nail' }}
          >
            {/* Name Field */}
            <Form.Item
              label={<span style={{ fontSize: '0.9em',margin:"0px" }}>Name</span>} 
              name="name"
              style={{ marginBottom: '1px' }} 
            
              rules={[{ required: true, message: 'Please enter your name!' }]}
            >
              <Input  placeholder="Enter your name" />
            </Form.Item>

          

            {/* Phone Number Field */}
            <Form.Item
              label={<span style={{ fontSize: '0.9em',margin:"0px" }}>Phone Number</span>} 
              name="mobileNo"
              style={{ marginBottom: '1px' }} 
              rules={[
                { required: true, message: 'Please enter your phone number!' },
                { pattern: /^[0-9]{10}$/, message: 'Please enter a valid 10-digit phone number!' },
              ]}
            >
              <Input placeholder="Enter your phone number" />
            </Form.Item>

            {/* Select Field */}
            <Form.Item
              label={<span style={{ fontSize: '0.9em',margin:"0px" }}>Choose Your Service</span>} 
              name="service_id"
              style={{ marginBottom: '1px' }} 
              rules={[{ required: true, message: 'Please choose a service!' }]}
            >
              <Select>
                {categoryList.length > 0 && categoryList.map((item,index)=>(
                  <Option value={item?._id}>
                 {item?.categoryName}
                  </Option>
                ))}
               
              </Select>
            </Form.Item>

            {/* Message Field */}
           

            {/* Submit Button */}
            <Form.Item  style={{ marginBottom: '0px',marginTop:"8px" }} >
              
              <Button type="primary" htmlType="submit" block style={{ backgroundColor: 'var(--primary)', borderColor: 'var(--primary)' }}>
                Submit Your Message
              </Button>
            </Form.Item>
          </Form>
        </div>

        {/* Footer Note */}
        <div style={{ color: 'red', textAlign: 'center' }}>
          *Not for hiring/vacancy
        </div>
      </Card>
    </div>
  );
};

export default EnquiryForm;
