import React from "react";
import ContactButton from "./ContactButton";
import { FcGoogle } from "react-icons/fc";

const Testimonials = () => {
  return (
    <section className="know-more-contaner">
      <div className="container">
        <div>
          <div className="section-header">
            <h1> Testimonials</h1>
          </div>
          <div className="testimonial-container">
            <div className="testimonial-item">
              <span>
                "I absolutely love the convenience of Cute Nails! The nail
                artist was so professional and talented. My nails look better
                than ever, and I didn’t have to leave my house!"
              </span>
              <div className="testimonial-author">
                <div className="testimonial-author-name">
                  <span className="author-name">Mr Arjun Patel</span>
                  <span>Enterpreneur</span>
                </div>
                <div>
                  <FcGoogle size={30} />
                </div>
              </div>
            </div>
            <div className="testimonial-item">
              <span>
                "As a working mom, it's hard to find time for self-care. Cute
                Nails makes it easy with their at-home services. The quality is
                top-notch, and the convenience is unbeatable"
              </span>
              <div className="testimonial-author">
                <div className="testimonial-author-name">
                  <span className="author-name">Dr Rajesh Malhotra</span>
                  <span>Senior Physician</span>
                </div>
                <div>
                  <FcGoogle size={30} />
                </div>
              </div>
            </div>
            <div className="testimonial-item">
              <span>
                "I was impressed by the hygiene standards. The tools were
                sterilized, and the products were safe. My nails look fabulous!"
              </span>
               <div className="testimonial-author">
                <div className="testimonial-author-name">
                  <span className="author-name">Mrs Ananya Sharma</span>
                  <span>Business Consultant</span>
                </div>
                <div>
                  <FcGoogle size={30} />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <ContactButton /> */}
      </div>
    </section>
  );
};

export default Testimonials;
