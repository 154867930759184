import { Card } from 'antd';
import React from 'react';
import thumbsup from '../assest/thumb-ups.png'
import three from '../assest/three.png'
import search from '../assest/search-data.png'
import ContactButton from './ContactButton';

const KnowMoreAbout = () => {
    return (
        <section className='know-more-contaner'>
            <div  className="container">

                <div>
                    <div className="section-header">
                   <h1> Know More About Us</h1>
                    </div>
                    <div className='know-more-titel-text'>
                    At Cute Nails, we believe in making beauty accessible without compromising on quality. Our at-home nail care service offers the latest trends in nail art, manicures, and pedicures delivered by highly skilled and certified nail technicians. We pride ourselves on maintaining the highest standards of hygiene, using premium, non-toxic products, and sterilized tools to ensure a safe and luxurious experience. With flexible booking options, we cater to your convenience, serving clients across Gurgaon, Noida, Greater Noida, and Ghaziabad. Whether it's a special occasion or regular pampering, we bring the salon experience right to your doorstep—no hassle, just beautiful nails.
                    </div>
                </div>
                <div>
                <StatsSection />

                </div>
                {/* <ContactButton/> */}
            </div>

        </section>
    );
}
const StatsCard = ({ icon, number, label }) => {
    return (
      <div className="stats-card">
        <div className="icon-container">
          <img src={icon} alt="icon" className="icon" />
        </div>
        <div className="number">{number}</div>
        <div className="label">{label}</div>
      </div>
    );
  };
  
  const StatsSection = () => {
    return (
      <div className="stats-section">
        <StatsCard icon={thumbsup} number="100+" label="Expert Artist" />
        <StatsCard icon={three} number="12" label="Years Expertise" />
        {/* <StatsCard icon={search} number="12,507+" label="Total Case" /> */}
      </div>
    );
  };

export default KnowMoreAbout;
