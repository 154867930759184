import { React, useEffect, useMemo, useRef, useState } from "react";
// import "../../style/header.css";
import {
    GoogleMap,
    Marker,
    useJsApiLoader,
    MarkerF,
} from "@react-google-maps/api";
import GooglePlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from "react-google-places-autocomplete";
import { Input, Skeleton, Spin } from "antd";
import axios from "axios"
// import { useCurrentLocation } from "../auth/AuthScreen";
const containerStyle = {
    width: "100%",
    height: "300px",
};

const ShowMap = ({ setLat, setLng, lat, lng }) => {
    const [value, setValue] = useState(null);
    const [lable, setLabel] = useState(null);
    const [latLng, setLatLng] = useState(null);
    // const { userLocation } = useCurrentLocation()

 const MapApiKey = process.env.REACT_APP_MAP_API;


    if (value) {
        const { label } = value;
        setLabel(label);
    }

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: MapApiKey,
        libraries: ["places"],
    });

    const center = useMemo(() => ({ lat: 28.7041, lng: 77.1025 }), []);

    // console.log("userLocation?.lat", userLocation?.lat)
    // console.log("userLocation?.lat", userLocation?.lon)

    const onLoadHandler = (marker) => {
        console.log("marker: ", marker);
    };

    if (!isLoaded) {
        return <Skeleton />;
    }
    const mapOptions = {
        disableDefaultUI: false, // Set to true to hide all default UI controls
        zoomControl: true, // Set to false to hide zoom control
        mapTypeControl: false, // Set to false to hide map type control
        scaleControl: true, // Set to false to hide scale control
        streetViewControl: true, // Set to false to hide street view control
        fullscreenControl: true, // Set to false to hide fullscreen control
    };



    const handleSelect = async (value) => {
        try {
            const results = await geocodeByAddress(value.label);
            const latLng = await getLatLng(results[0]);
            setLatLng(latLng)
            console.log('Selected Address:', value);
            console.log('Latitude:', latLng.lat);
            console.log('Longitude:', latLng.lng);
        } catch (error) {
            console.error('Error fetching location:', error);
        }
    };

    const handleMarkerDragEnd = (event) => {
        const newPosition = {
            lat: event.latLng.lat(),
            lng: event.latLng.lng(),
        };
        // setMarkerPosition(newPosition);
        setLat(newPosition.lat)
        setLng(newPosition.lng)
        console.log(newPosition)
    };

    return (
        <section>
             <div className="search_place mb-2">
                <GooglePlacesAutocomplete
                    apiKey={MapApiKey}
                    selectProps={{
                        value,
                        onChange: (value) => handleSelect(value)
                    }}
                />
            </div>
            <div className="show_map">
                <GoogleMap
                    zoom={latLng != null ? 20 : 15}
                    center={latLng != null ? latLng : center}
                    options={mapOptions}
                    mapContainerStyle={containerStyle}
                    onClick={(ev) => {
                        setLat(ev.latLng.lat());
                        setLng(ev.latLng.lng());


                    }}
                >
                    <MarkerF
                        onLoad={onLoadHandler}
                        position={latLng != null ? latLng : center}
                        draggable={true}
                        onDragEnd={handleMarkerDragEnd}
                    />
                </GoogleMap>
            </div>
           
        </section>
    );
};

export default ShowMap;