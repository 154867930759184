import React, { useState } from "react";
import { Button, Modal, Form, Input, Divider, Alert } from "antd";
import "../../style/Style.css";
import Logo from "../../assest/logo.png"
import { useNavigate } from "react-router-dom"
import OptVerify from "./OtpVerify";
import { SignUpUser } from "../../service/APIService";


const Signup = ({ handleCancel}) => {
    const [form] = Form.useForm();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isOtpSent, setIsOtpSent] = useState(false);
    const [number, setNumber] = useState(null)
    const [error, setError] = useState(null)

    const handleUserNumber = (e) => {
        setNumber(e.target.value)
    }
    const showModal = () => {
        setIsModalOpen(true);
        handleCancel()
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancelSign = () => {
        setIsModalOpen(false);
    };
    const onFinish = async (value) => {

        try {
            await SignUpUser(value)
                .then((res) => {
                    console.log("user register", res);
                    if (res.status == 201) {
                        onReset();
                        setIsOtpSent(true)
                    }
                    else if (res.status == 200) {
                        setError(res.data.message);

                    }
                })
                .catch((err) => {
                    console.warn(err);
                });
        } catch (error) {
            console.log(error);
        }
    };

    const onReset = () => {
        form.resetFields();
    };
    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };
    const body = {
        "fullName": "Sachin Pathak",
        "mobileNo": "8860305177",
        "email": "pathaks411@gmail.com"
    }
    const validateMaxLength = (_, value) => {
        if (value && value.length > 10) {
            return Promise.reject(new Error('Please Enter Valid Number OR less then 10 digit!'));
        }
        return Promise.resolve();
    };
    return (
        <div className="log_page">
            {/* <SignUpConfirmation confirm={confirm} /> */}
            <Button type="link" shape="round" onClick={showModal}>
                Signup
            </Button>
            <Modal

                footer={false}
                width={450}
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancelSign}
                style={{
                    top: 100,
                }}
            >
                <div className="login_pag">
                    <div className="log_head">
                        <img src={Logo} />

                    </div>
                    <div className="login_title">
                       

                        <p>{isOtpSent == true ? "Verity OTP" : "Signup "}</p>
                        {isOtpSent == true ? <p >otp send on <spna style={{ color: "green" }}>******{number != null && number.slice(-4)}</spna> view sms</p> : null}
                    </div>
                    {
                        isOtpSent == true ?
                            <OptVerify isOtpSent={isOtpSent} setIsModalOpen={setIsModalOpen} number={number} onFinish={onFinish}/> :
                            <div className="signup_form">
                                {error != null ? <Alert message={error} type="error" showIcon className="mb-2" /> : null}

                                <Form
                                    layout="vertical"
                                    name="basic"
                                    initialValues={{
                                        remember: true,
                                    }}
                                    onFinish={onFinish}
                                    onFinishFailed={onFinishFailed}
                                    autoComplete="off"
                                >
                                    <Form.Item
                                        name="fullName"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please Enter Your Full Name!",
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Enter your Name" variant="filled" />
                                    </Form.Item>
                                    <Form.Item

                                        name="mobileNo"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please Enter Your Mobile Number!",
                                            },
                                            {
                                                pattern: /^[0-9]*$/,
                                                message: 'Please Enter only Numbers!',
                                            },
                                            { validator: validateMaxLength }
                                        ]}
                                    >
                                        <Input placeholder="Enter your Number" variant="filled" onChange={handleUserNumber} />
                                    </Form.Item>
                                    <Form.Item

                                        name="email"

                                        rules={[
                                            {
                                                type: 'email',
                                            },
                                            {
                                                required: true,
                                                message: "Please Enter Valid Email !",
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Enter your Email" variant="filled" />
                                    </Form.Item>
                                    <Form.Item>
                                        <div className="log_continue">
                                            <Button
                                                type="primary"
                                                htmlType="submit"

                                            >
                                                Signup
                                            </Button>
                                        </div>
                                    </Form.Item>
                                </Form>
                            </div>
                    }


                </div>

               
            </Modal>
        </div>
    );
};
export default Signup;