import React from "react";
import HeroSection from "../../pages/HeroSection";
import BookingListing from "../../pages/BookingListing";
import WhyChooseUs from "../../pages/WhyChooseUs";
import HomeFaqCard from "../../pages/HomeFaqCard";
import HomeFooterCard from "../../pages/HomeFooterCard";
import MobileBottomNavigationBar from "../../pages/MobileBottomNavigationBar";
import { useWindowSize } from "react-use";
import KnowMoreAbout from "../../pages/KnowMoreAbout";
import Testimonials from "../../pages/Testimonials";
import ContactUs from "../../pages/ContactUs";

function Home() {
  const { width, height } = useWindowSize();
  return (
    <div>
      <HeroSection />
      <BookingListing />
      <KnowMoreAbout/>
      <WhyChooseUs />
      <Testimonials/>
      <HomeFaqCard />
      <ContactUs />
      <HomeFooterCard />
      {width < 801 && <MobileBottomNavigationBar id={1} />}
    </div>
  );
}

export default Home;
