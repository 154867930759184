import React from "react";
// import "../../public/images/why_2.png";
import management from "../assest/experience-management.png";
import successful from "../assest/successful.png";
import affordable from "../assest/affordable.png";
import three from "../assest/three.png";
import artist from "../assest/artist.png";
import searchfile from "../assest/search-file.png";
import handshake from "../assest/hand-shake.png";
import ContactButton from "./ContactButton";
import nailImage from '../assest/nail-art-5653459.jpg'

const WhyChooseUs = () => {
  const items = [
    {
      title: "12 Years Expertise",
      description:
        "Our highly skilled technicians are dedicated to delivering impeccable nail services with precision and care.",
      icon: management,
    },
    {
      title: "Long Lasting Premium Products",
      description:
        "Our Nails Last 45+ Days. We exclusively use top-quality brands like Nail Artistry, OPI, and Blue Sky for a flawless finish that lasts.",
      icon: successful,
    },
    {
      title: "Experience Positivity",
      description:
        "Step into a world of serenity and luxury with our meticulously designed interiors, providing the perfect backdrop for a relaxing pampering session.",
      icon: handshake,
    },
    {
      title: "Premium At-Home Service",
      description:
        "Enjoy salon-quality nail care in the comfort of your home. We bring the full nail salon experience to you!",
      icon: three,
    },
    {
      title: "Expert Nail Artists",
      description:
        "Our team consists of experienced professionals who specialize in trendy nail art and designs.",
      icon: artist,
    },
    {
      title: "Hygiene & Safety First",
      description:
        "We prioritize cleanliness, using sterilized tools and high-quality, non-toxic products.",
      icon: searchfile,
    },
    {
      title: "Affordable & Convenient",
      description:
        "Get stunning nails without stepping out. Book your appointment online and choose a time that suits you best!",
      icon: affordable,
    },
  ];

  return (
    <section id="why-choose-us">
      <div className="container" data-aos="fade-up">
        <div class="section-header">
          <h2>Why Choose CuteNails?</h2>
          {/* <p>You can explore your booking using serivce category </p> */}
        </div>
        {/* <div>
          <img src="../images/why_2.png" alt="image" />
          <div>Trained and Verified Experts</div>
          <div>
            We at Yes Madam believe in working with the utmost professionalism.
            Thus, our clients are served only by well-experienced and skilled
            professionals.
          </div>
        </div> */}
        {/* <div className="design-container">
          <div className="design-item">
            <img
              src="../images/trained.jpg"
              alt="Experts"
              className="design-image"
            />
            <h3 className="design-title">Trained and Verified Experts</h3>
            <p className="design-text">
              We at Cute Nails believe in working with the utmost
              professionalism. Thus, our clients are served only by
              well-experienced and skilled professionals.
            </p>
          </div>
          <div className="design-item">
            <img
              src="../images/ontime.jpg"
              alt="image"
              className="design-image"
            />
            <h3 className="design-title">On Time Service</h3>
            <p className="design-text">
              Our commitment to punctuality ensures that you receive your
              services right on time. We value your time and ensure that our
              professionals arrive as scheduled.
            </p>
          </div>
          <div className="design-item">
            <img
              src="../images/valuemoney.png"
              alt="Prices"
              className="design-image"
            />
            <h3 className="design-title">Transparent and Affordable Prices</h3>
            <p className="design-text">
              As we believe in 100% transparent pricing module, all our at-home
              salon and wellness services are quite affordable and
              budget-friendly.
            </p>
          </div>
        </div> */}
        <div className="why-choose-sub-container">
          <div className="why-choose-sub-container-item1">
            {items.map((item, index) => (
              <div className="design-box" key={index}>
                <div className="icon-box-container">
                  <img src={item.icon} alt="icon" className="icon-why-choose" />
                </div>
                <div className="icon-box-text-container">
                  <h6>{item.title}</h6>
                  <span className="box-text">{item.description}</span>
                </div>
              </div>
            ))}
          </div>
          <div className="why-choose-sub-container-item2">
            <img src={nailImage} alt="image" className="why-choose-right-image" />
          </div>
        </div>
        {/* <ContactButton /> */}
      </div>
    </section>
  );
};

export default WhyChooseUs;
