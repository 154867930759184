import React, { useEffect, useState } from 'react'
import "../style/Style.css"
import OrderSummary from './OrderSummary'
import { Row, Col } from "react-bootstrap"
import PaymentSummary from './PaymentSummary'
import { Card, message } from 'antd'
import LoginPage from '../authentication/auth/LoginPage'
import { useAuth } from '../authentication/context/screenContext'
import { useDispatch, useSelector } from 'react-redux'
import { selectCartItems } from '../redux/CartSlice'
import { FetchGeneralSetting, FetchUserProfile } from '../service/APIService'
import { setSettingDetails } from '../redux/OrderSlice'


function ViewCart() {
  const { token,logout,CurrentUserInfo } = useAuth()

  const dispatch = useDispatch();

  const showGeneralSetting = async () => {
    try {
      await FetchGeneralSetting(token)
        .then((res) => {
          console.log(" general setting", res);
          if (res.status == 200) {
            dispatch(setSettingDetails(res.data.data));
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    showGeneralSetting();
  }, [token]);

  const ShowUserProfileDetails = async () => {
    try {
        await FetchUserProfile(token)
            .then((res) => {
                console.log("profile", res);
                if (res.status == 200) {
                    CurrentUserInfo(res.data.data)
                    localStorage.setItem("userData", JSON.stringify(res.data.data))


                } else if (res.data.code == 283) {
                    message.error(res.data.message)
                    logout()
                }
            })
            .catch((err) => {
                console.log(err.message);
            });
    } catch (error) {
        console.log(error);
    }
};

useEffect(() => {
    ShowUserProfileDetails()
}, [])


  return (
    <div className="container">
      <div className="view_cart">
        <Row>
          <Col md="6">
           <OrderSummary />
          </Col>
          <Col md="6">
            <PaymentSummary />
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default ViewCart