import React, { useEffect, useState } from "react";
import { Carousel, message } from "antd";

import { Row, Col } from "react-bootstrap";
import { Card } from "antd";
import ShowAllServiceList from "./ShowAllServiceList";
import CartSection from "./CartSection";
import "../style/Style.css";
import { useAuth, useScreen } from "../authentication/context/screenContext";
import CartBottomSheet from "./CartBottomSheet";
import { useLocation } from "react-router-dom";
import { FetchCategoryBanner } from "../service/APIService";
import BannerDefault from "../assest/bannerPlace.png";
import BookingModal from "./BookingModal";

import { useNavigate } from "react-router-dom";
import { FetchCategoryList } from "../service/APIService";
import { Empty, Skeleton } from "antd";
// import { useAuth } from '../authentication/context/screenContext';

function ServcieCarosel() {
  const { state } = useLocation();
  const { screenWidth } = useScreen();
  const [add, setAdd] = useState(false);
  const [banerData, setBannerData] = useState([]);
  const { token, logout } = useAuth();

  const navigate = useNavigate();
  const [categoryList, setCategoryList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const showHeroBannerList = async () => {
    try {
      await FetchCategoryBanner()
        .then((res) => {
          console.log(" banner list", res);
          if (res.status == 200) {
            setBannerData(res.data.data);
          } else if (res.data.code == 283) {
            message.error(res.data.message);
            logout();
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    showHeroBannerList();
  }, []);

  ///////////////card list funtion///////
  const showCategoryListServiceCarsel = async () => {
    try {
      await FetchCategoryList()
        .then((res) => {
          console.log(" category list", res);
          if (res.status == 200) {
            setCategoryList(res.data.data);
            setIsLoading(true);
          } else if (res.data.code == 283) {
            message.error(res.data.message);
            logout();
          }
        })
        .catch((err) => {
          message.error(err.message);
        });
    } catch (error) {
      console.log(error);
      setIsLoading(true);
    }
  };

  useEffect(() => {
    showCategoryListServiceCarsel();
  }, []);

  return (
    <div className="show_carousel">
      <div className="service-carosel-booking-list-container">
        {" "}
        {/* <div className="row"> */}
        {!isLoading ? (
          <Skeleton loading={!isLoading} active avatar></Skeleton>
        ) : categoryList?.length == 0 ? (
          <Empty />
        ) : (
          categoryList &&  categoryList?.map((item) => (
            <div
              className="parent-card-container-of-service-carosel-booking-list-container"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div className="sub-parent-card-container-of-service-carosel-booking-list-container">
                <div className="card-body-service-carosel-booking-list-container">
                  <img
                    src={item?.categoryImage}
                    id="book_img"
                    onClick={() =>
                      navigate(`/service-details/${item._id}`, {
                        state: item,
                      })
                    }
                  />
                  <p className="card-body-service-carosel-booking-list-container-title">
                    {item.categoryName}
                  </p>
                </div>
              </div>
            </div>
          ))
        )}
        {/* </div> */}
      </div>
      {/* <Carousel effect="fade" autoplay>
        {banerData?.length == 0 ? <img src={BannerDefault} id='slide_image' /> : banerData.map((item) => (
          <img src={item.bannerImage} id='slide_image' />
          // </div>
        ))}
      </Carousel> */}

      <div className="show_service_item">
        <Card size="small" title={state.categoryName}>
          <Row>
            <Col md={7}>
              <ShowAllServiceList key={state._id} add={add} setAdd={setAdd} />
            </Col>
            <Col md={5}>
              {screenWidth > 800 ? (
                <CartSection add={add} setAdd={setAdd} />
              ) : null}
              {screenWidth < 800 ? <CartBottomSheet /> : null}
            </Col>
          </Row>
        </Card>
      </div>
    </div>
  );
}

export default ServcieCarosel;
