import { useEffect, useState } from 'react'
import { BottomSheet } from 'react-spring-bottom-sheet'
import { BiCategory } from "react-icons/bi";
import 'react-spring-bottom-sheet/dist/style.css'
import { Avatar, Badge, Button, Card, FloatButton } from 'antd';
import "../style/Style.css"
import { MdOutlineShoppingCart } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import CartSection from './CartSection';
import { useSelector } from 'react-redux';
import { selectCartItems, selectTotalAmount } from '../redux/CartSlice';

function CartBottomSheet() {
    const [open, setOpen] = useState(false)
    const navigate = useNavigate()
    const handleClose = () => setOpen(false);
    const cartItems = useSelector(selectCartItems);
    const totalAmount = useSelector(selectTotalAmount);


    return (
        <div className='cat_bottomSheet'>
            <div className="cart_avtar">
                {cartItems.length != 0 ?
                    <Badge count={cartItems.length}>
                        <Button shape='circle' size='large' onClick={() => setOpen(true)} icon={
                            <MdOutlineShoppingCart className='icon_bottom_sheet' />
                        }>
                        </Button>
                    </Badge> : null}
            </div>
            <BottomSheet open={open} onDismiss={handleClose}>
                <div className="show_mobile_cat">
                    <CartSection closeBottomSheet={setOpen}/>
                </div>
            </BottomSheet>
        </div>
    )
}
export default CartBottomSheet