import { createContext, useState, useContext, useEffect } from "react";

const ScreenContext = createContext();

export const useScreen = () => {
  return useContext(ScreenContext);
};

export const ScreenProvider = ({ children }) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  // Function to update screen dimensions
  const updateScreenDimensions = () => {
    setScreenWidth(window.innerWidth);
    setScreenHeight(window.innerHeight);
  };

  useEffect(() => {
    updateScreenDimensions(); // Set initial dimensions

    // Add event listener for window resize
    window.addEventListener("resize", updateScreenDimensions);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateScreenDimensions);
    };
  }, []);

  return (
    <ScreenContext.Provider value={{ screenWidth, screenHeight }}>
      {children}
    </ScreenContext.Provider>
  );
};

// LocationContext.js

// const LocationContext = createContext();
// export const useCurrentLocation = () => {
//   return useContext(LocationContext);
// };

// export const LocationProvider = ({ children }) => {
//   const [userLocation, setUserLocation] = useState(JSON.parse(sessionStorage.getItem("location")) || null);

//   return (
//     <LocationContext.Provider value={{ userLocation, setUserLocation }}>
//       {children}
//     </LocationContext.Provider>
//   );
// };

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem("token") || null);
  const [fcmtoken, setFcmToken] = useState(null);

  const [currentUser, setCurrentUser] = useState(
    JSON.parse(localStorage.getItem("userData")) || null
  );

  const login = (newToken) => {
    setToken(newToken);
  };

  const logout = () => {
    setToken(null);
    localStorage.clear();
    // Optionally, clear any other session-related data
  };

  const CurrentUserInfo = (userData) => {
    setCurrentUser(userData);
  };

  return (
    <AuthContext.Provider
      value={{ token, login, logout, currentUser, CurrentUserInfo,setFcmToken,fcmtoken }}
    >
      {children}
    </AuthContext.Provider>
  );
};
