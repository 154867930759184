// cartSlice.js
import { createSlice } from "@reduxjs/toolkit";

const nextDate = new Date();
const defautlDate = nextDate.toLocaleDateString();
const initialState = {
  userLocation: {
    detials: null,
  },
  settings: {
    detials: null,
  },
  serviceSlot: {
    dates: null,
    times: null,
  },
  user: {
    details: null,
  },
  serviceInfo: null,
  subtotal: 0,
  discount: {
    details: null,
  },
  totalAmount: 0,
  paybleAmount: 0,
  gstAmount: 0,
  discountValue: 0,
  discountAmount: 0,
};

const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    setUserLocation(state, action) {
      state.userLocation.detials = action.payload;
    },
    setSubTotalAmount(state, action) {
      state.subtotal = action.payload;
    },
    setGstAmount(state, action) {
      state.gstAmount = action.payload;
    },
    setTotalAmount(state, action) {
      state.totalAmount = action.payload;
    },
    setPaybleAmount(state, action) {
      state.paybleAmount = action.payload;
    },
    addService(state, action) {
      state.serviceInfo = action.payload;
    },
    setSettingDetails(state, action) {
      state.settings.detials = action.payload;
    },
    setServiceSlotDate(state, action) {
      state.serviceSlot.dates = action.payload;
    },
    setServiceSlotTime(state, action) {
      state.serviceSlot.times = action.payload;
    },

    setDiscountDetails(state, action) {
      state.discount.details = action.payload;
    },
    setDiscountValue(state, action) {
      state.discountValue = action.payload;
    },
    setDiscountAmount(state, action) {
      state.discountAmount = action.payload;
    },
    RemoveDiscountDetails(state, action) {
      state.discount.details = action.payload;
    },
  },
});

export const {
  setUserLocation,
  addService,
  setSettingDetails,
  setDiscountDetails,
  setSubTotalAmount,
  setTotalAmount,
  setServiceSlotDate,
  setServiceSlotTime,
  setPaybleAmount,
  setGstAmount,
  RemoveDiscountDetails,
  setDiscountValue,
  setDiscountAmount,
} = orderSlice.actions;

export const selectUserLocation = (state) => state.orders.userLocation;
export const selectSubTotalAmount = (state) => state.orders.subtotal;
export const selectTotalAmount = (state) => state.orders.totalAmount;
export const selectServiceSlotDate = (state) => state.orders.serviceSlot;
export const selectServiceSlotTime = (state) => state.orders.serviceSlot;
export const selectPaybleAmount = (state) => state.orders.paybleAmount;
export const selectAddService = (state) => state.orders.serviceInfo;
export const selectSetting = (state) => state.orders.settings;
export const selectDiscount = (state) => state.orders.discount;
export const selectGstAmount = (state) => state.orders.gstAmount;
export const selectDiscountValue = (state) => state.orders.discountValue;
export const selectDiscountAmount = (state) => state.orders.discountAmount;

export default orderSlice.reducer;
