import { Button, Divider, Input, message, notification } from "antd";
import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import "../../style/Style.css";
import { useAuth } from "../context/screenContext";
import { VerifyOTPS } from "../../service/APIService";

function OptVerify({ isOtpSent, setIsModalOpen, number, onFinish = () => {} }) {

  const { login, fcmtoken } = useAuth();
  const [otp, setOtp] = useState("");

  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(30);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  const resendOTP = () => {
    setMinutes(1);
    setSeconds(30);
    onFinish()
  };

  const body = {
    mobileNo: number,
    otp: otp,
    fcmToken: fcmtoken || ""

  }
  console.log("body", body)



  const submitHandler = async () => {
    try {
      await VerifyOTPS(body)
        .then((res) => {
          console.log("verify otp", res);
          if (res.status == 201) {
            // message.success("login")
            console.log("token", res.data.data.accessToken)
            localStorage.setItem("token", res.data.data.accessToken);
            login(res.data.data.accessToken);

            setIsModalOpen(false)
            notification.success({
              placement: "topRight",
              message: res.data.message,

            });

          } else if (res.status == 200) {
            message.error(res.data.message,)
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    } catch (error) {
      console.log(error);
    }
  };




  return (
    <div className="otp_verification">

      <div className="otp_vefiyed">
        <OtpInput
          value={otp}
          onChange={setOtp}
          numInputs={4}
          placeholder={0}
          // renderSeparator={<span>-</span>}
          renderInput={(props) => (
            <div className="ot_field">
              <Input {...props} style={{ width: "3rem", height: "3rem" }} placeholder="0" variant="filled" />
            </div>
          )}
        />
        {isOtpSent == true && (
          <div className="countdown_text">
            {seconds > 0 || minutes > 0 ? (
              <p>
                Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
                {seconds < 10 ? `0${seconds}` : seconds}
              </p>
            ) : (
              <div className="not_recived_otp">
                <p>Didn't receive OTP?</p>
                <Button

                  type="link"
                  disabled={seconds > 0 || minutes > 0}
                  style={{
                    color: seconds > 0 || minutes > 0 ? "#DFE3E8" : "#5AC268",
                  }}
                  onClick={() => resendOTP()}
                >
                  Resend
                </Button>
              </div>
            )}

          </div>
        )}

        <div className="verify_continue">
          <Button type="default" shape="round" onClick={submitHandler}>Verify & Continue</Button>
        </div>
      </div>
    </div>
  );
}

export default OptVerify;