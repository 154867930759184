import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import { Form } from "antd"
import dayjs from 'dayjs';
import { TimePicker, DatePicker } from 'antd';
const format = 'HH:mm';

const BookingModal = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const onChange = (date, dateString) => {
        console.log(date, dateString);
    };

    const onFinish = (values) => {
        console.log('Success:', values);
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    return (
        <>
            <div className="text-center">
                <button type="button" className="btn" onClick={showModal}>Buy Now</button>
            </div>

            <Modal title="Book Your Service" footer={false} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <div className="show_form">
                    <Form layout='vertical' onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off">
                        <Form.Item label="Select Service Date" name="date" rules={[
                            {
                                required: true,
                                message: 'Please Choose Date!',
                            },
                        ]}>
                            <DatePicker onChange={onChange} style={{ width: "100%" }} />
                        </Form.Item>
                        <Form.Item label="Select Service Date" name="date" rules={[
                            {
                                required: true,
                                message: 'Please Choose Time!',
                            },
                        ]}>
                            <TimePicker format={format} style={{ width: "100%" }} />
                        </Form.Item>
                        <Form.Item>
                            <div className="text-center" id='book_btn'>

                            <Button type='default' htmlType='submit' shape='round'>Submit</Button>
                            </div>
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
        </>
    );
};
export default BookingModal;