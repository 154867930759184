import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy" style={{ lineHeight: "1.6", fontFamily: "Arial, sans-serif" }}>
      <center><h1>Privacy Policy</h1></center>
      <p><strong>Last updated: June 25, 2024</strong></p>

      <p>
        This Privacy Policy describes Our policies and procedures on the collection, use, and disclosure of Your information
        when You use the Service and tells You about Your privacy rights and how the law protects You. We use Your Personal data 
        to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance 
        with this Privacy Policy.
      </p>
      
      <br></br><h3>Interpretation and Definitions</h3>
      
      <br></br><h3>Interpretation</h3>
      <p>
        The words of which the initial letter is capitalized have meanings defined under the following conditions.
        The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.
      </p>
      
      <br></br><h3>Definitions</h3>
      <p>For the purposes of this Privacy Policy:</p>
      <ul>
        <li>
          <strong>Account:</strong> means a unique account created for You to access our Service or parts of our Service.
        </li>
        <li>
          <strong>Affiliate:</strong> means an entity that controls, is controlled by, or is under common control with a party.
        </li>
        <li>
          <strong>Application:</strong> refers to Cute Nails, the software program provided by the Company.
        </li>
        <li>
          <strong>Company:</strong> (referred to as either "the Company", "We", "Us", or "Our" in this Agreement) refers to 
          Cute Nails, 961/5 Gali Number 8 Patel Nagar, Gurugram, Haryana, 122001.
        </li>
        <li><strong>Country:</strong> refers to: Haryana, India.</li>
        <li>
          <strong>Device:</strong> means any device that can access the Service such as a computer, a cellphone, or a digital tablet.
        </li>
        <li><strong>Personal Data:</strong> is any information that relates to an identified or identifiable individual.</li>
        <li><strong>Service:</strong> refers to the Application.</li>
        <li>
          <strong>Service Provider:</strong> means any natural or legal person who processes the data on behalf of the Company.
        </li>
        <li>
          <strong>Usage Data:</strong> refers to data collected automatically, either generated by the use of the Service or from
          the Service infrastructure itself.
        </li>
        <li><strong>You:</strong> means the individual accessing or using the Service.</li>
      </ul>

      <br></br><h3>Collecting and Using Your Personal Data</h3>

      <br></br><h3>Types of Data Collected</h3>

      <h4>Personal Data</h4>
      <p>
        While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be
        used to contact or identify You. Personally identifiable information may include, but is not limited to:
      </p>
      <ul>
        <li>Email address</li>
        <li>First name and last name</li>
        <li>Phone number</li>
        <li>Address, State, Province, ZIP/Postal code, City</li>
      </ul>

      <h4>Usage Data</h4>
      <p>Usage Data is collected automatically when using the Service.</p>
      <p>
        Usage Data may include information such as Your Device's Internet Protocol address (e.g., IP address), browser type, 
        browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, 
        unique device identifiers, and other diagnostic data.
      </p>

      <h4>Information Collected while Using the Application</h4>
      <p>
        While using Our Application, we may collect, with Your prior permission:
      </p>
      <ul>
        <li>Information regarding your location</li>
        <li>Pictures and other information from your Device's camera and photo library</li>
      </ul>

      <br></br><h3>Use of Your Personal Data</h3>
      <p>The Company may use Personal Data for the following purposes:</p>
      <ul>
        <li><strong>To provide and maintain our Service:</strong> Including monitoring the usage of our Service.</li>
        <li><strong>To manage Your Account:</strong> Manage your registration as a user of the Service.</li>
        <li><strong>To contact You:</strong> Contact you by email, phone, or other electronic communication.</li>
        <li><strong>To manage Your requests:</strong> To attend and manage requests made by You.</li>
        <li><strong>For business transfers:</strong> Your information may be used in the context of a merger, restructuring, or sale.</li>
      </ul>

      <br></br><h3>Retention of Your Personal Data</h3>
      <p>
        The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy.
      </p>

      <br></br><h3>Transfer of Your Personal Data</h3>
      <p>
        Your information may be transferred to — and maintained on — computers located outside of Your jurisdiction where
        the data protection laws may differ.
      </p>

      <br></br><h3>Delete Your Personal Data</h3>
      <p>
        You have the right to delete or request that We assist in deleting the Personal Data that We have collected about You.
      </p>

      <br></br><h3>Disclosure of Your Personal Data</h3>
      <p>
        We may disclose Your Personal Data under certain circumstances, such as in the case of a business transaction,
        to comply with legal obligations, or to protect the personal safety of Users.
      </p>

      <br></br><h3>Security of Your Personal Data</h3>
      <p>
        While We strive to use commercially acceptable means to protect Your Personal Data, no method of transmission over the
        Internet or method of electronic storage is 100% secure.
      </p>

      <br></br><h3>Children's Privacy</h3>
      <p>
        Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information
        from anyone under 13.
      </p>

      <br></br><h3>Links to Other Websites</h3>
      <p>
        Our Service may contain links to other websites that are not operated by Us. We have no control over the content or 
        privacy policies of third-party websites.
      </p>

      <br></br><h3>Changes to this Privacy Policy</h3>
      <p>
        We may update Our Privacy Policy from time to time. You are advised to review this Privacy Policy periodically for
        any changes.
      </p>

      <br></br><h3>Contact Us</h3>
      <p>If you have any questions about this Privacy Policy, you can contact us by email at: help.cutenails@gmail.com.</p>
    </div>
  );
};

export default PrivacyPolicy;
