import React, { useEffect } from 'react'
import { Row, Col } from "react-bootstrap"
import { Card } from "antd"
import CategoryCard from './CategoryCard'
import ServcieCarosel from './ServcieCarosel'
import '../style/Style.css'
import { FetchGeneralSetting } from '../service/APIService'
import { setSettingDetails } from '../redux/OrderSlice'
import { useAuth } from '../authentication/context/screenContext'
import { useDispatch } from 'react-redux'

function ServiceDetails() {
  const { token } = useAuth()
  const dispatch = useDispatch()
 

  return (
    <div className='service_listing'>
      <Row>
        <Col md={3}>
          <CategoryCard />
        </Col>
        <Col md={9}>
          <ServcieCarosel />
        </Col>
      </Row>
    </div>
  )
}

export default ServiceDetails