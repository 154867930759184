import React, { useEffect, useState } from 'react'
import { Card, Button, Space, Tag, Alert, List, Radio, Divider, Skeleton, Collapse, message } from "antd"
import { MdLocationOn } from "react-icons/md";
import '../style/Style.css'
import AddNewAddress from './AddNewAddress';
import SloteList from './SloteList';
import { MdOutlineAccessTime } from "react-icons/md";
import { MdCurrencyRupee } from "react-icons/md";
import OrderPlaced from './OrderPlaced';
import { FetchSaveAddressList } from '../service/APIService';
import { useAuth } from '../authentication/context/screenContext';
import { useDispatch, useSelector } from 'react-redux';
import { selectPaybleAmount, selectServiceSlotDate, selectUserLocation, setServiceSlotDate, setServiceSlotTime, setUserLocation } from '../redux/OrderSlice';


function OrderSummary() {
    const { token, currentUser, logout } = useAuth()
    const [address, setAddress] = useState(false)
    const [slot, setSlot] = useState(false)
    const [saveAddress, setSaveAddress] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [value, setValue] = useState(null);
    const dispatch = useDispatch()
    const userLocation = useSelector(selectUserLocation)
    const serviceSlote = useSelector(selectServiceSlotDate)
    const paybleAmt = useSelector(selectPaybleAmount)


    const gridStyle = {
        width: '100%',
        textAlign: 'start',
    };

    const onChange = (e) => {
        console.log("radio checked", e.target.value);
        setValue(e.target.value);
        dispatch(setUserLocation(e.target.value))

    };



    const showSaveAddressList = async () => {
        try {
            await FetchSaveAddressList(token)
                .then((res) => {
                    console.log(" address list", res);
                    if (res.status == 200) {
                        setSaveAddress(res.data.data);
                        setIsLoading(true)
                    } else if (res.data.code == 283) {
                        message.error(res.data.message)
                        logout()
                    }
                })
                .catch((err) => {
                    console.log(err.message);
                });
        } catch (error) {
            console.log(error);
            setIsLoading(true)
        }
    };

    useEffect(() => {
        showSaveAddressList()
    }, [])

    const handleClose = () => {
        dispatch(setServiceSlotDate(null))
        dispatch(setServiceSlotTime(null))
    }

    return (
        <div className='order_summary'>
            <Card title="Order Summary">
                <Card.Grid hoverable={false} style={gridStyle}>
                    <div className="show_login">
                        <Space>

                            <Button icon={<MdLocationOn className='loc_icon' />}></Button>
                            <div className="log_details">

                                <p>Send Booking Detials </p>
                                <p>+91-{currentUser?.mobileNo}</p>
                            </div>
                        </Space>
                    </div>
                </Card.Grid>
                <Card.Grid hoverable={false} style={gridStyle}>
                    <div className="show_address">

                        <div className="show_login">
                            <Space>

                                <Button icon={<MdLocationOn className='loc_icon' />}></Button>
                                <div className="log_details">

                                    <p>Address</p>
                                </div>
                            </Space>
                        </div>
                        <div className="show_save_loction">
                            <Collapse  >
                                <Collapse.Panel header="Your Recent Save Address" key="1">
                                    <List

                                        className="demo-loadmore-list"
                                        itemLayout="horizontal"
                                        dataSource={saveAddress}
                                        renderItem={(item) => (
                                            <>
                                                {!isLoading ? <Skeleton active avatar={{ shape: 'circle' }} title={false} paragraph={{ rows: 3 }} /> : <List.Item>
                                                    <List.Item.Meta
                                                        avatar={
                                                            <Radio.Group onChange={onChange} value={value}>
                                                                <Radio value={item} />{" "}
                                                            </Radio.Group>
                                                        }
                                                        title={item.type}
                                                        description={item.houseNo + ""+item.area.substring(0, 60)}
                                                    />
                                                </List.Item>}
                                            </>

                                        )}
                                    />
                                </Collapse.Panel>
                            </Collapse>


                            <Divider dashed >OR</Divider>
                        </div>
                        <AddNewAddress setAddress={setAddress} showSaveAddressList={showSaveAddressList} />
                    </div>
                </Card.Grid>
                <Card.Grid hoverable={false} style={gridStyle}>
                    <div className="show_slote">

                        <div className="slote_item">
                            <Space>

                                <Button icon={<MdOutlineAccessTime className='loc_icon' />}></Button>
                                <div className="log_details">
                                    <p>Available Slot </p>
                                </div>
                            </Space>
                        </div>
                        {serviceSlote.dates != null && serviceSlote?.times!=null ? <Alert message={`Your Selected Slot ${serviceSlote?.dates}, ${serviceSlote?.times} `} showIcon type="success" closable afterClose={handleClose} /> : null}
                        {userLocation?.detials != null ? <SloteList setSlot={setSlot} /> : null}
                    </div>
                </Card.Grid>
                <Card.Grid hoverable={false} style={gridStyle} >
                    <div className="payment_mode">

                        <div className="pay_item">
                            <Space>
                                <Button icon={<MdCurrencyRupee className='loc_icon' />}></Button>
                                <div className="log_details">
                                    <p>Payment Methods</p>
                                </div>
                            </Space>
                        </div>
                        {userLocation?.detials != null && serviceSlote != null ? <div className='pay_btn'>
                            <Button type='primary'><div className='d-flex justify-content-between'>
                                <p>₹ {paybleAmt.toFixed()} Cash</p>
                                <OrderPlaced />
                            </div></Button>
                        </div> : null}

                    </div>
                </Card.Grid>
            </Card>
            <div className="cancel mt-2">
                <Alert message={<div><h5>Cancellation & reschedule policy</h5>
                    <p>Free cancellations/reschedules if done more than 3 hrs before the service or if a professional isnt assigned. A fee will be charged otherwise</p>
                </div>} type="info" />

            </div>
        </div>
    )
}

export default OrderSummary