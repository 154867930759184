import React from "react";
import { Layout, Row, Col } from "antd";
import { useScreen } from "../authentication/context/screenContext";
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from "react-icons/fa";
import { Link } from "react-router-dom";

const { Footer } = Layout;

const HomeFooterCard = () => {
  const { screenWidth } = useScreen()

  return (
    <section>
      <Footer className="app-footer">
        <div className="image-cover">
          <img
            src="../images/logo.png"
            alt="Cute Nails"
            className="footer-logo-cover"
          />
        </div>
        {
          screenWidth > 800 ?
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={6}>
                {/* <div className="img-footer-section">
                <div className="second-img-footer-section">
              <img
                src="../images/logo.png"
                alt="Cute Nails"
                className="footer-logo"
              />
              </div>
            </div> */}
              </Col>
              <Col xs={24} sm={3}>

              </Col>
              <Col xs={24} sm={6}>
                <div className="footer-section">
                  <h3>Company</h3>
                  <ul>

                    <li>
                      <Link to="/terms" exact >
                        Terms & conditions
                      </Link>
                    </li>
                    <li>
                      <Link to="/privacy" exact >
                        Privacy Policy
                      </Link>
                    </li>
                    <li>
                      <Link to="/refund" exact >
                        Return & Refund Policy
                      </Link>
                    </li>
                  </ul>
                </div>
              </Col>

              <Col xs={24} sm={6}>
                <div className="footer-section">
                  <h3>Register as a professional? 
                  <a href="https://play.google.com/store/apps/details?id=com.nails.cutenails"><strong> Click here</strong></a>
                  </h3>
     
                  <h3>Social links</h3>
                  <div className="social-links">
                    {/* <a href="#twitter"><FaTwitter style={{ color: "blue", fontSize: "30px" }} /></a> */}
                    <a href="https://www.facebook.com/Official.CuteNails?mibextid=ZbWKwL"><FaFacebook style={{ color: "#1877F2", fontSize: "30px" }} /></a>
                    <a href="https://www.instagram.com/official.cutenails?igsh=bHh6djB2OWM2bjg4"><FaInstagram style={{ color: "#FCAF45", fontSize: "30px" }} /></a>
                    <a href="#linkedin"><FaLinkedin style={{ color: "#0077B5", fontSize: "30px" }} /></a>
                  </div>
                  {/* <div className="app-download">
                <a href="#app-store">App Store</a>
                <a href="#play-store">Google Play</a>
              </div> */}
                </div>
              </Col>
            </Row>
            :
            <div>

              <div className="social-links">
                {/* <a href="#twitter"><FaTwitter style={{ color: "blue", fontSize: "30px", marginRight: "5px" }} /></a> */}
                <a href="https://www.facebook.com/Official.CuteNails?mibextid=ZbWKwL" target="_blank"><FaFacebook style={{ color: "#1877F2", fontSize: "30px" }} /></a>
                <a href="https://www.instagram.com/official.cutenails?igsh=bHh6djB2OWM2bjg4" target="_blank"><FaInstagram style={{ color: "#FCAF45", fontSize: "30px" }} /></a>
                <a href="#linkedin" target="_blank"><FaLinkedin style={{ color: "#0077B5", fontSize: "30px" }} /></a>
              </div>

            </div>
        }

        <div className="footer-bottom">
          <p>
            © Copyright 2024 Cute Nails. All rights reserved. | CIN:
            U74140DL2014PTC274413
          </p>
        </div>
      </Footer>
    </section>
  );
};

export default HomeFooterCard;
