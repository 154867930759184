import React, { useState } from "react";
import { Button, Modal, Form, Input, Divider, Alert } from "antd";
import "../../style/Style.css";
import Logo from "../../assest/logo.png"
import { useNavigate } from "react-router-dom"
import Signup from "./Signup";
import OptVerify from "./OtpVerify";
import { sendOTP } from "../../service/APIService";

const LoginPage = ({ totalAmount }) => {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [number, setNumber] = useState(null)
  const [error, setError] = useState(null)

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setDisableSubmit(inputValue.trim() === '');
    setNumber(inputValue)
  };

  const showModal = () => {
    setIsModalOpen(true);

  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const onFinish = async (value) => {
    try {
      await sendOTP(value)
        .then((res) => {
          console.log("send otp", res);
          if (res.status == 201) {
            setIsOtpSent(true)
          }
          else if (res.status == 200) {
            setError(res.data.message);
          }
        })
        .catch((err) => {
          console.warn(err);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const validateMaxLength = (_, value) => {
    if (value && value.length > 10) {
      return Promise.reject(new Error('Please Enter Valid Number !'));
    }
    return Promise.resolve();
  };

  return (
    <div className="log_page">
      <Button type='primary' id='view_cart' onClick={showModal}><div className='view_cart_btn'><span>₹ {totalAmount}</span><span>Login</span></div></Button>

      <Modal
        footer={false}
        width={450}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        style={{
          top: 100,
        }}
      >
        <div className="login_pag">
          <div className="log_head">
            <img src={Logo} />
          </div>
          <div className="login_title">
            <h5>{isOtpSent == true ? "Verity OTP" : "Login "}</h5>
            {isOtpSent == true ? <p >otp send on <spna style={{ color: "green" }}>******{number != null && number.slice(-4)}</spna> view sms</p> : null}
          </div>
          {isOtpSent == true ? (
            <OptVerify isOtpSent={isOtpSent} setIsModalOpen={setIsModalOpen} number={number} onFinish={onFinish} />
          ) : (
            <div className="log_form">
              {error != null ? <Alert message={error} type="error" showIcon className="mb-2" /> : null}
              <Form
                layout="vertical"
                name="basic"
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Form.Item
                  name="mobileNo"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Your Number!",
                    },
                    {
                      pattern: /^[0-9]*$/,
                      message: 'Please Enter only Numbers!',
                    },
                    { validator: validateMaxLength }
                  ]}
                >
                  <Input size="large" placeholder="Enter your Number" variant="filled" prefix="+91" onChange={handleInputChange} />
                </Form.Item>
                <Form.Item>
                  <div className="log_continue">

                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={disableSubmit}
                      shape="round"
                      size="large"
                    >
                      Continue..
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            </div>
          )}
        </div>
        {isOtpSent != true && <div className="signup_user">
          <p>If you are not register , please signup</p>
          <Signup handleCancel={handleCancel} />
        </div>}
      </Modal>
    </div>
  );
};
export default LoginPage;