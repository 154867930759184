import React, { useEffect, useState } from 'react';
import { Button, Modal, Card, Input, Divider, Alert } from "antd";
import '../style/Style.css'
import { useDispatch } from 'react-redux';
import {  setServiceSlotDate, setServiceSlotTime } from '../redux/OrderSlice';
import { slotLists } from '../service/APIService';

const SloteList = ({ setSlot }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
  
    const [selecteddates, setSelecteddates] = useState("")
    const [selectDate, setSlelectDate] = useState(new Date())
    const [selectTime, setSelectTime] = useState("")
 
    const dispatch = useDispatch()

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const onFinish = (value) => {
        console.log(value)
        setSlot(true)
        setIsModalOpen(false);

    }


    // function getDate() {
    //     // Always use the current date
    //     let currentDate = new Date();
    
    //     const date = [];
    //     // Generate dates for the next 15 days
    //     for (let i = 0; i < 15; i++) {
    //         const nextDate = new Date(currentDate);
    //         nextDate.setDate(currentDate.getDate() + i);
    //         date.push({
    //             days: nextDate.toLocaleDateString('en-US', { weekday: 'short' }).substring(0, 3),
    //             dates: nextDate.getDate(),
    //             fullDate: nextDate.toLocaleDateString()
    //         });
    //     }
    
    //     // Get current time in 12-hour format
    //     const currentTime12Hour = currentDate.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
    
    //     // Convert 12-hour time to 24-hour format
    //     const convertTo24Hour = (time12h) => {
    //         const [time, modifier] = time12h.split(' ');
    //         let [hours, minutes] = time.split(':');
    //         if (hours === '12') {
    //             hours = '00';
    //         }
    //         if (modifier === 'PM') {
    //             hours = parseInt(hours, 10) + 12;
    //         }
    //         return `${hours}:${minutes}`;
    //     };
    
    //     // Convert current time to 24-hour format
    //     const currentTime24Hour = convertTo24Hour(currentTime12Hour);
    
    //     // Parse selecteddates to a Date object
    //     const selectedDateObject = selecteddates ? new Date(selecteddates) : null;
    
    //     // Update slotLists with enabled/disabled status based on the selected date and current time
    //     const updatedSlotLists = slotLists.map(slot => {
    //         const slotTime24Hour = convertTo24Hour(slot.time);
    //         // Disable slots only if the selected date is the current date and the slot time has passed
    //         return {
    //             ...slot,
    //             disabled: selectedDateObject && selectedDateObject.toLocaleDateString() === currentDate.toLocaleDateString() && currentTime24Hour > slotTime24Hour
    //         };
    //     });
    
    //     return {
    //         date,
    //         updatedSlotLists
    //     };
    // }
    function getDate() {
        let currentDate = new Date();
    
        const date = [];
        for (let i = 0; i < 15; i++) {
            const nextDate = new Date(currentDate);
            nextDate.setDate(currentDate.getDate() + i);
            date.push({
                days: nextDate.toLocaleDateString('en-US', { weekday: 'short' }).substring(0, 3),
                dates: nextDate.getDate(),
                fullDate: nextDate.toLocaleDateString('en-GB') // 'en-GB' for DD/MM/YYYY format
            });
        }
    
        const currentTime12Hour = currentDate.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
        const convertTo24Hour = (time12h) => {
            const [time, modifier] = time12h.split(' ');
            let [hours, minutes] = time.split(':');
            if (hours === '12') {
                hours = '00';
            }
            if (modifier === 'PM') {
                hours = parseInt(hours, 10) + 12;
            }
            return `${hours}:${minutes}`;
        };
    
        const currentTime24Hour = convertTo24Hour(currentTime12Hour);
    
        const selectedDateObject = selecteddates ? new Date(selecteddates.split('/').reverse().join('/')) : null;
    
        const updatedSlotLists = slotLists.map(slot => {
            const slotTime24Hour = convertTo24Hour(slot.time);
            return {
                ...slot,
                disabled: selectedDateObject && 
                          selectedDateObject.toLocaleDateString('en-GB') === currentDate.toLocaleDateString('en-GB') && 
                          currentTime24Hour > slotTime24Hour
            };
        });
    
        return {
            date,
            updatedSlotLists
        };
    }
    
    
    const handleDate = (item) => {
        setSlelectDate(item.dates)
        console.log("seleecffdsfsdf", item.fullDate)
        setSelecteddates(item.fullDate)
        dispatch(setServiceSlotDate(item.fullDate))
        getDate()

    }
    const handleTyme = (slote) => {
        console.warn(slote.time)
        setSelectTime(slote.time)
        dispatch(setServiceSlotTime(slote.time))

    }



    return (
        <>
            <div className="show_address">
                <Button type='primary' block onClick={showModal}>Select Service Slot</Button>
            </div>

            <Modal title="When should the professional arrive?" footer={false} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <div className="slote_listings">
                    {getDate()?.date?.map((item) => (
                        <Card onClick={() => handleDate(item)} className={selectDate == item.dates && "date_delected"}>
                            <p>{item.days}</p>
                            <p>{item.dates} </p>
                        </Card>

                    )
                    )}

                </div>
                <div className="slot_timing">
                    <h6>Select start time of service</h6>
                    <div className="slot_time_list">
                        {getDate()?.updatedSlotLists?.map((slot) => (
                            <Button disabled={slot.disabled == true} key={slot.id} onClick={() => handleTyme(slot)} className={selectTime == slot.time && "time_delected"}>{slot.time}</Button>
                        ))}

                    </div>

                </div>
                <Divider dashed />
                <div className="checkout_btn">

                    <Button onClick={() => onFinish()}>Proceed to Checkout</Button>
                </div>
            </Modal>
        </>
    );
};



export default SloteList