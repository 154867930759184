import { LikeOutlined, MessageOutlined, StarOutlined } from '@ant-design/icons';
import React, { useEffect, useRef, useState } from 'react';
import { Button, List, Space, Radio, message, Skeleton, Spin } from 'antd';
import Logo from "../assest/logo.png";
import "../style/Style.css";
import ShowMoreText from 'react-show-more-text';
import { useDispatch, useSelector } from 'react-redux';
import { addItem, removeItem, selectCartItems } from '../redux/CartSlice';
import { useParams } from 'react-router-dom';
import { FetchServiceList } from '../service/APIService';
import { MdOutlineAccessTime } from 'react-icons/md';
import ServiceImageDefault from "../assest/bannerPlace.png";
import { useAuth } from '../authentication/context/screenContext';

function executeOnClick(isExpanded) {
    console.log(isExpanded);
}

function ShowAllServiceList({ add, setAdd }) {
    const { logout } = useAuth();
    const dispatch = useDispatch();
    const cartItems = useSelector(selectCartItems);
    const { id } = useParams();

    const [isLoading, setIsLoading] = useState(false);
    const [isPageLoading, setIsPageLoading] = useState(false);
    const [serviceList, setServiceList] = useState([]);
    const [current, setCurrent] = useState(1);
    const [hasMore, setHasMore] = useState(true); // Initialize hasMore state
    const observer = useRef();
    const lastProductElementRef = useRef();

    console.log("current",current);
    console.log("observer",observer);
    console.log("id",id);


    const ShowServiceList = async () => {
        setIsLoading(true);
        setIsPageLoading(true);
        try {
            const res = await FetchServiceList(current, id);
            console.log("service list", res);
            if (res.status === 200) {
                setServiceList(prev => [...prev, ...res.data.data]);
                setHasMore(res.data.data.length > 0);
            } else if (res.data.code === 283) {
                message.error(res.data.message);
                logout();
            }
        } catch (err) {
            message.error(err.message);
        } finally {
            setIsLoading(false);
            setIsPageLoading(false);
        }
    };

    useEffect(() => {
        ShowServiceList();
    }, [current]);

    useEffect(() => {

        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore && !isLoading) {
                
                setCurrent(prevPage => prevPage + 1);
            }
        }, { threshold: 1.0 });

        if (lastProductElementRef.current) {
            observer.current.observe(lastProductElementRef.current);
        }

        // Clean up observer on component unmount
        return () => {
            if (observer.current) observer.current.disconnect();
        };
    }, [isLoading, hasMore]);

    const handleAddItem = (item) => {
        dispatch(addItem(item));
    };

    useEffect(() => {
        localStorage.setItem("cart", JSON.stringify(cartItems));
    }, [cartItems]);

    const handleDelItem = (item) => {
        dispatch(removeItem(item));
        const cart = JSON.parse(localStorage.getItem("cart")) || [];
        const updatedCart = cart.filter(cartItem => cartItem._id !== item._id);
        localStorage.setItem("cart", JSON.stringify(updatedCart));
    };

    return (
        <>
            {isPageLoading && <Skeleton avatar={false} active />}
            <List
                itemLayout="vertical"
                size="medium"
                dataSource={serviceList}
                renderItem={(item) => (
                    <List.Item
                        key={item._id}
                        extra={<div className='service_Image'>
                            {item.serviceImage != null ? <img id="prd-img"  alt="service" src={item.serviceImage} /> : <img id="prd-img" alt="default" src={ServiceImageDefault} />}
                            <div className="book_service">
                                {cartItems.find((cartItem) => cartItem._id === item._id) ? (
                                    cartItems.map((cartItem) => {
                                        if (cartItem._id === item._id) {
                                            return (
                                                <Button.Group key={cartItem._id}>
                                                    <Button onClick={() => handleDelItem(item)}>-</Button>
                                                    <Button>{cartItem.unit}</Button>
                                                    <Button onClick={() => handleAddItem(item)}>+</Button>
                                                </Button.Group>
                                            );
                                        }
                                        return null;
                                    })
                                ) : (
                                    <Button id="add_btn" type="default" onClick={() => handleAddItem(item)}>Add</Button>
                                )}
                            </div>
                        </div>}
                    >
                        <List.Item.Meta
                            avatar={<img src={Logo} id='list_logo' />}
                            title={<span>{item.serviceName}</span>}
                            description={
                                <div>
                                    <p><b>₹ {item.price}</b></p>
                                    <MdOutlineAccessTime className='duration_icon' />
                                    <span style={{ textTransform: "lowercase" }}>{item.timeDuration} . {item.timeUnit}</span>
                                </div>
                            }
                        />
                        <ul>
                            <ShowMoreText
                                lines={1}
                                more={<span className="content-css">View more</span>}
                                less={<span className="content-css">View less</span>}
                                anchorClass="show-more-less-clickable"
                                onClick={executeOnClick}
                                expanded={false}
                                truncatedEndingComponent={"... "}
                            >
                                {item.description.map((desc, index) =>
                                    <li key={index}>{desc}</li>
                                )}
                            </ShowMoreText>
                        </ul>
                    </List.Item>
                )}
            />
              <div ref={lastProductElementRef}></div>
            <div className='text-center'>{isLoading && <Spin />}</div>
        </>
    );
}

export default ShowAllServiceList;
