import React, { useEffect, useState } from 'react'
import Logo from "../../assest/logo.png"
import "../../style/Style.css"
import { Button } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import HeaderMenu from './HeaderMenu'
import { useAuth, useScreen } from '../../authentication/context/screenContext'
import MobileSideMenu from './MobileSideMenu'
import LoginPage from '../../authentication/auth/LoginPage'
import LoginHeader from '../../authentication/auth/LoginHeader'
import { FaHome } from 'react-icons/fa'

function HeaderNav() {
    const navigate = useNavigate()
    const [isSticky, setIsSticky] = useState(false);
    const { screenWidth } = useScreen()
    const { token } = useAuth()

    // useEffect(() => {
    //     const handleScroll = () => {
    //         if (window.scrollY > window.innerHeight / 2) {
    //             setIsSticky(true);
    //         } else {
    //             setIsSticky(false);
    //         }
    //     };

    //     window.addEventListener('scroll', handleScroll);

    //     return () => {
    //         window.removeEventListener('scroll', handleScroll);
    //     };
    // }, []);

    return (
        <div className={`navbar ${isSticky ? 'sticky' : ''}`}>

            <div className="logo">
                {
                    window.innerWidth > 750 ?
                        <FaHome className='home_icon' onClick={() => navigate("/")} />
                        : ""
                }

                <img src={Logo} onClick={() => navigate("/")} />
            </div>
            <div className="log_btn">
                {!token ? <LoginHeader /> : window.innerWidth > 800 ? <HeaderMenu /> : <MobileSideMenu />}
            </div>

        </div>
    )
}

export default HeaderNav