import React, { useEffect, useState } from "react";
import "../style/Style.css";
import officeBag from "../assest/officebag.png";
import exportImg from "../assest/artist.png";
import affordable from "../assest/affordable.png";
import { Carousel, message, Modal, Button } from "antd"; // Import Modal and Button
import { FetchHeroBanner } from "../service/APIService";
import bannerDefault from "../assest/bannerPlace.png";
import { useAuth, useScreen } from "../authentication/context/screenContext";
import Marquee from "react-fast-marquee";
import { FaStarOfLife } from "react-icons/fa";
import EnquiryForm from "./EnquiryForm";
import ContactButton from "./ContactButton";

function HeroSection() {
  const [banerData, setBannerData] = useState([]);
  const { logout } = useAuth();
  const screenWidth = useScreen();
  const [isModalVisible, setIsModalVisible] = useState(false); // State to control modal visibility
  const marqueeList = [
    "FLAT 10% OFF ON FIRST ORDER",
    "TRENDING DESIGN",
    "NAIL ART",
    "NAIL NOURISHING",
    "LONG LASTING",
    "NAIL NOURISHING",
  ];
  console.log("screenWidth", screenWidth);
  // Show/Hide Modal Handlers
  const showModal = () => setIsModalVisible(true);
  const handleCancel = () => setIsModalVisible(false);

  const showHeroBannerList = async () => {
    try {
      await FetchHeroBanner()
        .then((res) => {
          console.log(" banner list", res);
          if (res.status === 200) {
            setBannerData(res.data.data);
          } else if (res.data.code === 283) {
            message.error(res.data.message);
            logout();
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    showHeroBannerList();
  }, []);

  return (
    <section>

      <div className="hero">
        <Carousel effect="scrollx" autoplay>
          {banerData?.length == 0 ? <img src={bannerDefault
          } id='hero_image' /> : banerData.map((item) => (
            <div className='hero_carousel'>
              <img src={item.bannerImage
              } id='hero_image' />
              <div className="hero-container" >

                <div className="hero-logo">
                  {/* <img src={Logo} /> */}
                  {/* {
                                        window.innerWidth > 700 ?
                                            <>
                                                <div className="flate">

                                                    <h1>FLAT</h1>
                                                    <h2>10% off</h2>
                                                </div>
                                                <hr />
                                                <h4>ON FIRST SERVICE</h4>
                                            </>
                                            : ""
                                    } */}
                  <div className="header-query-page-subcontainer-2">

                    {screenWidth.screenWidth < 750 ? (
                      <Button type="primary" block onClick={showModal} className="enquiremob" style={{ backgroundColor: 'var(--primary)', borderColor: 'var(--primary)' }}>
                        Enquire Now
                      </Button>
                    ) : (
                      <EnquiryForm />
                    )}

                    <Modal visible={isModalVisible} onCancel={handleCancel} footer={null}>
                      <EnquiryForm />
                    </Modal>
                  </div>

                </div>
                {/* <div className="hero-content" >
                  <p> Nail Care & Nail Arts</p>
                  <span style={{ textTransform: "capitalize" }}> {item.title}</span>
                  <p> {item.description}</p>

                </div> */}

              </div>
            </div>
          ))}
        </Carousel>
        <div className='marquee-head'>
          <Marquee>
            {
              marqueeList.map(item => {
                return <>
                  <div className='marq-cotainer'>
                    <div className='marq-items'>{item}</div>
                    <FaStarOfLife style={{ color: "white", marginTop: "10px" }} />
                  </div>
                </>
              })
            }
          </Marquee>
        </div>
      </div>



      {/* <div className="hero">
        <div className="header-container">
          <div className="header-query-page">
            <div className="header-query-page-container">
              <div className="header-query-page-subcontainer-1">
                <div className="header-query-page-subcontainer-1-left">
                  <div>
                    <span style={{ fontWeight: "700", color: "white", fontSize: "1.2rem" }}>
                      Get Nail Extension at Home in 30 Mins Affordable home nail
                      art services just for you.
                    </span>
                  </div>
                  <div
                    style={{
                      fontWeight: "400",
                      color: "white",
                      marginLeft: "1.5vw",
                      fontSize: "1.2rem",
                    }}
                  >
                    <span>
                      <img src={officeBag} style={{ width: "24px", height: "24px" }} alt="Office Bag Icon" />
                    </span>
                    <span style={{ marginLeft: "1.5vw" }}>12 Years Expertise</span>
                  </div>
                  <div
                    style={{
                      fontWeight: "400",
                      color: "white",
                      marginLeft: "1.5vw",
                      fontSize: "1.2rem",
                    }}
                  >
                    <span>
                      <img src={exportImg} style={{ width: "24px", height: "24px" }} alt="Expert Icon" />
                    </span>
                    <span style={{ marginLeft: "1.5vw" }}>100+ Expert Artist</span>
                  </div>

                </div>
              </div>

              <div className="header-query-page-subcontainer-2">

                {screenWidth.screenWidth < 750 ? (
                  <Button type="primary" block onClick={showModal} style={{ backgroundColor: 'var(--primary)', borderColor: 'var(--primary)' }}>
                    Enquire Now
                  </Button>
                ) : (
                  <EnquiryForm />
                )}
              </div>
            </div>
          </div>
        </div>


        <Modal visible={isModalVisible} onCancel={handleCancel} footer={null}>
          <EnquiryForm />
        </Modal>

        <div className="marquee-head">
          <Marquee>
            {marqueeList.map((item, index) => (
              <div key={index} className="marq-cotainer">
                <div className="marq-items">{item}</div>
                <FaStarOfLife style={{ color: "white", marginTop: "10px" }} />
              </div>
            ))}
          </Marquee>
        </div>
      </div> */}
    </section>
  );
}

export default HeroSection;
