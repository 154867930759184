import React from "react";
import { Link } from "react-router-dom";

import { useState } from "react";
import { useAuth } from "../authentication/context/screenContext";

const MobileBottomNavigationBar = (props) => {
  const [active, setActive] = useState(props.id);
  const [open, setOpen] = useState(true);
  const { token } = useAuth()

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  return (
    <div className="parent-navbar-conatiner">
      {/* <div className="navbar-other-display-component-conatiner">
   
    </div> */}

      {/* <footer className="parent-down-nav-bar-container"> */}
      <div className="parent-down-nav-bar-container">
        <div className="navbar-conatiner">
          <ul className="list">
            {/* <div style={{ position: "0" }} data-indicator class="indicator">
          <div class="corners"></div>
        </div> */}
            <li
              className={active === 0 ? "active" : "inactive"}
              onClick={() => {
                setActive(0);
              }}
            >
              <Link to={token ? "/my-booking" : ""} exact activeClassName="active" onClick={() => setOpen(false)}>

                <div className="link ">
                  <div className="icon">
                    <img
                      src="./images/logistic.png"
                      alt="dashborad"
                      style={{ heigh: "1.2rem", width: "1.6rem" }}
                    ></img>
                  </div>
                  <div className="text">My Orders</div>
                </div>
              </Link>
            </li>
            <li
              className={active === 1 ? "active" : "inactive"}
              onClick={() => {
                setActive(1);
              }}
            >
              <Link to="/" exact activeClassName="active" onClick={() => setOpen(false)}>
                <div className="link">
                  <div className="icon">
                    <img src="./images/home.png" alt="service"></img>
                  </div>
                  <div className="text">Home</div>
                </div>
              </Link>

            </li>
            <li
              className={active === 2 ? "active" : "inactive"}
              onClick={() => {
                setActive(2);
              }}
            >
              <Link to={token ? "/my-profile" : ""} exact activeClassName="active" onClick={() => setOpen(false)}>
                <div className="link">
                  <div className="icon">
                    <img src="./images/user.png" alt="profile"></img>
                  </div>
                  <div className="text">Profile</div>
                </div>
              </Link>
            </li>
          </ul>
        </div>
      </div>
      {/* </footer> */}
    </div>
  );
};

export default MobileBottomNavigationBar;
