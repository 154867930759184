import React from "react";
import {
  EditOutlined,
  EllipsisOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Avatar, Card } from "antd";
import Logo from "../assest/logo.png";
import "../style/Style.css";
import { useLocation } from "react-router-dom";
const { Meta } = Card;

function CategoryCard() {
  const { state } = useLocation();
  console.log("state", state);
  return (
    <div className="service_category">
      <Card cover={<img alt="example" src={state?.categoryImage} />}>
        <Meta
          avatar={<img src={Logo} id="avtar" />}
          title={state.categoryName}
          description="Show Service of This Category"
        />
      </Card>
    </div>
  );
}

export default CategoryCard;
