import React from 'react';
import { Dropdown, Space } from 'antd';
import { MdFilterList } from "react-icons/md";
import "../style/Style.css";
const STATUS = {
    pending: "PENDING",
    upcoming: "UPCOMING",
    ongoing: "ONGOING",
    completed: "COMPLETED",
    cancelled: "CANCELLED",
    notPicked: "NOTPICKED",
};

function MyOrderFilter({ setFilterStatus }) {
    const items = Object.entries(STATUS).map(([key, value], index) => ({
        label: <p className='menu_items' onClick={() =>{
            setFilterStatus(value)
        }}>{key.charAt(0).toUpperCase() + key.slice(1)}</p>,
        key: index.toString(),
    }));

    return (
        <Dropdown
            menu={{ items }}
            trigger={['click']}
        >
            <a onClick={(e) => e.preventDefault()}>
                <Space>
                    <span>Filter :</span>
                    <MdFilterList className='menu_icon' />
                </Space>
            </a>
        </Dropdown>
    );
}

export default MyOrderFilter;
