import React, { useState } from 'react';
import { Button, Divider, Drawer, Menu } from 'antd';
import { FaRegUserCircle, FaRegUser, FaUserCheck } from 'react-icons/fa';
import '../../style/Style.css'
import { Link } from 'react-router-dom';
import { AiOutlineInbox } from "react-icons/ai";
import { MdOutlineWifiCalling3 } from 'react-icons/md';
import { useAuth } from '../../authentication/context/screenContext';
import { BiLogOutCircle } from 'react-icons/bi';
const MobileSideMenu = () => {
    const { logout, currentUser } = useAuth()

    const [open, setOpen] = useState(false);
    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };

    const logoutHandle = () => {
        logout()
        localStorage.clear()
    }
    return (
        <div className='mobile_menu'>
            
            <FaRegUserCircle className='menu_icon' onClick={showDrawer} />
            <Drawer onClose={onClose} open={open} placement='left' >
                <div className="menu_cover">
                    <img src='https://img.freepik.com/free-vector/petals-pink-rose-spa-background_8829-2606.jpg' />
                    <div className="profileIcon">
                        {currentUser != null ? <img src={currentUser?.profilePic} /> :
                            <FaRegUserCircle className='menu_pro_icon' />}
                    </div>
                </div>
                <div className="menu_items">
                    <Menu defaultOpenKeys={['components']}>
                        <div className="sidebar_title">
                            {currentUser != null ?
                                <div>
                                    <h4>{currentUser?.fullName}</h4>
                                    <p>{currentUser?.mobileNo}</p>
                                </div>
                                : null}

                        </div>
                        <Divider />
                        <Menu.Item key="profile" className='menu_items'>
                            <Link to="/my-profile" exact activeClassName="active" onClick={() => setOpen(false)}>
                                <span><FaRegUser className="sideSubMenus" /></span> My Profile
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="booking" className='menu_items'>
                            <Link to="/my-booking" exact activeClassName="active" onClick={() => setOpen(false)}>
                                <span><AiOutlineInbox className="sideSubMenus" /></span> My Booking
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="booking" className='menu_items'>
                            <Link to="/" exact activeClassName="active" onClick={() => logoutHandle()}>
                                <span> <BiLogOutCircle className="sideSubMenus" />Logout</span>
                            </Link>
                        </Menu.Item>

                    </Menu>
                    <hr></hr>
                    <div className='terms-mobile'>
                
                     <Link to="/terms" exact >
                       Terms & conditions
                     </Link>
                    <br></br>
                     <Link to="/privacy" exact >
                       Privacy Policy
                     </Link>
                     <br></br>

                   <Link to="/refund" exact >
                       Return & Refund Policy
                     </Link>
                     <br></br>

                     <span>
                     Register as a professional? <a href='https://play.google.com/store/apps/details?id=com.nails.cutenails'>Click here</a>
                     </span>
                 </div>
                </div>
            </Drawer>
        </div>
    );
};

export default MobileSideMenu