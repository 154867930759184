import React from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Avatar, Badge, Dropdown, Space } from 'antd';
import { FaHome, FaRegUser, FaRegUserCircle, FaShoppingCart } from "react-icons/fa";
import "../../style/Style.css"
import { Link, useNavigate } from 'react-router-dom'
import { AiOutlineInbox } from 'react-icons/ai';
import { BiLogOutCircle } from "react-icons/bi";
import { useAuth } from '../../authentication/context/screenContext';
import { useDispatch, useSelector } from 'react-redux';
import { selectCartItems } from '../../redux/CartSlice';
function HeaderMenu() {
    const { logout } = useAuth()
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const cartItems = useSelector(selectCartItems);
    const items = [
        {
            label: <p className='menu_items'><Link to="/my-profile"><span><FaRegUser className="sideSubMenus" /></span>My Profile</Link> </p>,
            key: '0',
        },
        {
            label: <p className='menu_items'> <Link to="/my-booking"><span><AiOutlineInbox className="sideSubMenus" /></span>My Booking</Link></p>,
            key: '1',
        },
        {
            label: <p className='menu_items' onClick={() => logoutHandle()}> <Link to="/"><span><BiLogOutCircle className="sideSubMenus" /></span>Logout</Link></p>,
            key: '2',
        },
    ];

    const logoutHandle = () => {
        logout()
        localStorage.clear()
    }
    return (
        <>
            <Badge count={cartItems && cartItems.length}>
                 <FaShoppingCart className="cart-icon" onClick={() => cartItems.length > 0 ? navigate("/view-Cart") : ""} />
            </Badge>
            <Dropdown
                menu={{
                    items,
                }}
                trigger={['click']}
            >
                <a onClick={(e) => e.preventDefault()}>
                    <Space>
                        <FaRegUserCircle className='menu_icon' />
                    </Space>
                </a>
            </Dropdown>
        </>
    );
}


export default HeaderMenu