import React, { useEffect, useState } from 'react'
import BookingModal from './BookingModal'

import { useLocation, useNavigate } from "react-router-dom"
import { FetchCategoryList } from '../service/APIService';
import { Empty, Skeleton, message } from 'antd';
import { useAuth, useScreen } from '../authentication/context/screenContext';
import { Card, CardBody, CardFooter } from 'react-bootstrap';
import ContactButton from './ContactButton';



function BookingListing() {
    const { logout } = useAuth()
    const navigate = useNavigate()
    const screenWidth = useScreen()
    const [categoryList, setCategoryList] = useState([])
    const [isLoading, setIsLoading] = useState(false)


    const showCategoryList = async () => {

        try {
            await FetchCategoryList()
                .then((res) => {
                    console.log(" category list", res);
                    if (res.status == 200) {
                        setCategoryList(res.data.data);
                        setIsLoading(true)
                    } else if (res.data.code == 283) {
                        message.error(res.data.message)
                        logout()
                    }
                })
                .catch((err) => {
                    message.error(err.message);
                });
        } catch (error) {
            console.log(error);
            setIsLoading(true)
        }
    };

    useEffect(() => {
        showCategoryList()
    }, [])





    return (

        <section id="buy-tickets" className="section-with-bg">
            <div className="container" data-aos="fade-up">
                <div class="section-header">
                    <h2>Nail Studio at Home</h2>
                    <p>Affordable home nail art services are easily accessible throughout India. </p>
                </div>
                {
                     window.innerWidth  > 750 ?
                        <div className="row" >
                            {!isLoading ? <Skeleton loading={!isLoading} active avatar></Skeleton> : categoryList?.length == 0 ? <Empty /> : categoryList && categoryList.map((item) => (
                                <div className="col-lg-3 card-category" data-aos="fade-up" data-aos-delay="100">
                                    <div className="card mb-2 mb-lg-3" >
                                        <div className="card-body" >
                                            <img src={item.categoryImage} id='book_img' onClick={() => navigate(`/service-details/${item._id}`, { state: item })} />
                                            <p className="mt-2" id='booking_list_title'>{item.categoryName}</p>

                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        :
                        <div className="mobile-cat" >
                        {!isLoading ? <Skeleton loading={!isLoading} active avatar></Skeleton> : categoryList?.length == 0 ? <Empty /> : categoryList.map((item) => (
                               <Card className="mobile-card">
                                  <CardBody>
                                  <img src={item.categoryImage} id='book_img_mob' onClick={() => navigate(`/service-details/${item._id}`, { state: item })} />
                                            <p className="mt-2" id='booking_list_title_mob'>{item.categoryName}</p>

                                  </CardBody>
                                  
                               </Card>
                        ))}
                    </div>
                    }
            </div>
            {/* <ContactButton/> */}
        </section>

    )
}

export default BookingListing