import React, { useEffect, useState } from 'react';
import { AutoComplete, Button, Form, Input, Modal, Skeleton, Space, Upload, message } from 'antd';
import { useAuth } from '../authentication/context/screenContext';
import { FetchActivePincodeList } from '../service/APIService';
import clickIcon from "../assest/click-here-2.gif"



const ShowActivePincode = () => {
    const { token, logout } = useAuth()
    const { form } = Form.useForm()
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [activePincode, setActivePincode] = useState([])
    const [isLoading, setIsLoading] = useState(false)





    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const ShowActivePincodeList = async () => {
        try {
            await FetchActivePincodeList(token)
                .then((res) => {
                    console.log("active pin list", res);
                    if (res.status == 200) {
                        setActivePincode(res.data.data)
                        setIsLoading(true)
                    } else if (res.data.code == 283) {
                        message.error(res.data.message)
                        logout()
                    }
                })
                .catch((err) => {
                    console.log(err.message);
                });
        } catch (error) {
            console.log(error);
            setIsLoading(true)
        }
    };

    useEffect(() => {
        ShowActivePincodeList()
    }, [])


    return (
        <div className='upload_pincode_models'>
            <div className="import_file">
                <Button onClick={showModal} type='link'>View Pincode on Service Available</Button>
            </div>

            <Modal width={400} title="Servcie Available Pincode" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={false}>
                <div className="show_pinocde">
                    {!isLoading ? <Skeleton loading={!isLoading} active avatar></Skeleton> :
                        activePincode.map((item) => (
                        
                                <Space>
                                    <img src={clickIcon} className="click_image" />
                                    <p>{item.area} <span className='active_pin_light'>{item.pincode}</span></p>
                                </Space>
                          
                        ))}

                </div>
            </Modal>
        </div>
    );
};



export default ShowActivePincode