import { Button, Card, Form, Input, Upload, message, notification } from 'antd'
import React, { useEffect, useState } from 'react'
import '../style/Style.css'
import { MdOutlineLocationOn, MdOutlineLocalPhone } from "react-icons/md";
import { Row, Col } from 'react-bootstrap'
import { FetchUserProfile, UpdateUserProfile } from '../service/APIService';
import { useAuth } from '../authentication/context/screenContext';
import MobileBottomNavigationBar from './MobileBottomNavigationBar';


function MyProfile() {
    const [form] = Form.useForm();
    const { Meta } = Card;
    const { logout } = useAuth()
    const [uploder, setUploader] = useState(null)
    const [profile, setProfile] = useState([])
    const [formData, setFormData] = useState(new FormData());
    const [isLoading, setIsLoading] = useState(false)
    const { CurrentUserInfo, token } = useAuth()
    const [newImg, setNewImg] = useState(null)

    const ShowUserProfileDetails = async () => {
        try {
            await FetchUserProfile(token)
                .then((res) => {
                    console.log("profile", res);
                    if (res.status == 200) {
                        setProfile(res.data.data)
                        CurrentUserInfo(res.data.data)
                        localStorage.setItem("userData", JSON.stringify(res.data.data))
                        form.setFieldsValue({
                            fullName: res.data.data.fullName,
                            mobileNo: res.data.data.mobileNo,
                            email: res.data.data.email,
                            profilePic: res.data.data.profilePic,



                        });
                        setIsLoading(false)

                    } else if (res.data.code == 283) {
                        message.error(res.data.message)
                        logout()
                    }
                })
                .catch((err) => {
                    console.log(err.message);
                });
        } catch (error) {
            console.log(error);
            setIsLoading(false)
        }
    };

    useEffect(() => {
        ShowUserProfileDetails()
    }, [])


    const onReset = () => {
        form.resetFields();
    };
    const handlerImage = (file) => {
        setNewImg(file.file)
        setUploader(file.fileList)
    }

    const onFinish = async (value) => {
        console.log(value)
        try {
            newImg != null && formData.append("profilePic", value.profilePic.file);
            formData.append("fullName", value.fullName);
            formData.append("email", value.email);

            await UpdateUserProfile(token, formData)
                .then((res) => {
                    console.log("user profile update", res);
                    if (res.status == 201) {
                        notification.success({
                            placement: "topRight",
                            message: res.data.message,
                        });
                        setFormData(new FormData());
                        onReset()
                        setIsLoading(true)
                        ShowUserProfileDetails();
                        setUploader(null)
                        setNewImg(null)
                    }

                })
                .catch((err) => {
                    console.log(err.message);
                    setFormData(new FormData());

                });
        } catch (error) {
            console.log(error);
            setIsLoading(true)
            setFormData(new FormData());

        }
    };
    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };



    return (
        <>
        <div className='container'>
            <div className="profile">
                <Card >
                    <Form
                        form={form}
                        layout="vertical"
                        name="add-image"
                        className="images"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                    >
                        <div className="profile_img">

                            <Form.Item name="profilePic" label={`${uploder?.length == 1 ? "If You Want to Change Image Delete Exit Image " : "Select Image"}`} >

                                <Upload
                                    multiple={false}
                                    listType="picture-circle"
                                    beforeUpload={() => false} // To prevent automatic upload
                                    accept="jpg/jpeg/png"
                                    onChange={handlerImage}
                                // disabled={uploder?.length == 1}
                                >
                                    {uploder?.length != 1 ? <img src={profile?.profilePic} className="image_hold" /> : null}

                                </Upload>
                            </Form.Item>

                        </div>
                        <Row>

                            <Col md={12}>
                                <Form.Item
                                    label="Full Name"
                                    name="fullName"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please Enter Full Name",
                                        },
                                    ]}
                                >
                                    <Input placeholder="Enter Full Name" size='large' />
                                </Form.Item>
                            </Col>
                            <Col md={12}>
                                <Form.Item
                                    label="Email"
                                    name="email"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please Enter Email",
                                        },
                                    ]}
                                >
                                    <Input placeholder="Enter Email" size='large' />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>

                            <Col md={12}>
                                <Form.Item
                                    label="Number"
                                    name="mobileNo"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please Enter Number",
                                        },
                                    ]}
                                >
                                    <Input placeholder="Enter Number" disabled size='large' />
                                </Form.Item>
                            </Col>

                        </Row>

                        <div className="model_Submit">

                            <Button
                                htmlType="submit"
                            >
                                Update
                            </Button>
                        </div>
                    </Form>
                </Card>


            </div>
        </div>
        {window.innerWidth < 801 && <MobileBottomNavigationBar id={2}/>}

        </>

    )
}

export default MyProfile