// src/firebase.js
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyB9aumjyBX_So3HrNXY_lAWOpm29-mm_00",
  authDomain: "cutenails-7a31c.firebaseapp.com",
  projectId: "cutenails-7a31c",
  storageBucket: "cutenails-7a31c.appspot.com",
  messagingSenderId: "260750834148",
  appId: "1:260750834148:web:753a9b27c3f0d69a086ff1",
  measurementId: "G-JFXZP95850",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export  {messaging,getToken,onMessage};
