// cartSlice.js
import { createSlice } from "@reduxjs/toolkit";
const cartData = {
  unit: 1,
};
const calculateTotalAmount = (items) => {
  return items?.reduce((total, item) => total + (item.sub_total || 0), 0);
};

const cartSlice = createSlice({
  name: "cart",
  initialState: {
    items: JSON.parse(localStorage.getItem("cart")) || [],
    TotalAmount: calculateTotalAmount(
      JSON.parse(localStorage.getItem("cart")) || null
    ),
  },
  reducers: {
    addItem: (state, action) => {
      const ItemIndex = state.items.findIndex(
        (item) => item._id === action.payload._id
      );
      console.warn("ItemIndex", ItemIndex);
      if (ItemIndex >= 0) {
        state.items[ItemIndex].unit += 1;
        state.items[ItemIndex].sub_total =
          state.items[ItemIndex].price * state.items[ItemIndex].unit;
      } else {
        const temp = {
          ...action.payload,
          ...cartData,
          sub_total: action.payload.price * cartData.unit,
        };

        state.items.push(temp);
      }
      state.TotalAmount = state.items.reduce(
        (total, item) => total + item.sub_total,
        0
      );
      state.TotalAmount = calculateTotalAmount(state.items)
    },
    // Remove item quantity from cart
    removeItem: (state, action) => {
      const itemIndex = state.items.findIndex(
        (item) => item._id === action.payload._id
      );

      if (itemIndex >= 0 && state.items[itemIndex].unit > 1) {
        // Decrease quantity if unit is greater than 0
        state.items[itemIndex].unit -= 1;
        state.items[itemIndex].sub_total =
          state.items[itemIndex].price * state.items[itemIndex].unit;
        state.TotalAmount =
          state.items[itemIndex].price * state.items[itemIndex].unit;
      } else if (state.items[itemIndex].unit === 1) {
        state.items = state.items.filter(
          (item) => item._id !== action.payload._id
        );
      }
      state.TotalAmount = state.items.reduce(
        (total, item) => total + item.sub_total,
        0
      );
      state.TotalAmount = calculateTotalAmount(state.items)
    },

    
    clearCart: (state) => {
      state.items = [];
    },
  },
});


export const { addItem, removeItem, clearCart } = cartSlice.actions;
export const selectCartItems = (state) => state.cart.items;
export const selectTotalAmount = (state) => state.cart.TotalAmount;

export default cartSlice.reducer;
