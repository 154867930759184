import { Button, Card, Form, Input } from "antd";
import React from "react";
import { Deleteprofile } from "../service/APIService";

const DeleteProfile = () => {
  const onFinish = async (values) => {
    
    try{
        console.log("value",values);
        Deleteprofile(values).then((res)=>{
            console.log(res);
        })
    }catch (err){
        console.log(err);
    }
  };
  const onFinishFailed = (errorInfo) => {
    // message.error('Please complete the required fields!');
    console.log("Failed:", errorInfo);
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height:"80vh"
      }}
    >
      <Card
        bordered={true}
       
        headStyle={{ textAlign: "center", padding: "8px 0" }}
      >
        <Form
          name="delete-profile-form"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
          // initialValues={{ service: 'Gel Polish Natural Nail' }}
        >
          <Form.Item
            label="Phone Number"
            name="mobileNo"
            rules={[
              { required: true, message: "Please enter your phone number!" },
              {
                pattern: /^[0-9]{10}$/,
                message: "Please enter a valid 10-digit phone number!",
              },
            ]}
          >
            <Input placeholder="Enter your phone number" />
          </Form.Item>
          <Form.Item>
              <Button type="primary" htmlType="submit" block style={{ backgroundColor: 'var(--primary)', borderColor: 'var(--primary)' }}>
               Delete
              </Button>
            </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default DeleteProfile;
