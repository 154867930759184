import React, { useEffect } from 'react'
import { Card, List, Radio, Button, Image, Space, notification } from "antd"
import "../style/Style.css"
import EmptyCart from "../assest/cartEmpty.gif"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux'
import { addItem, removeItem, selectCartItems, selectTotalAmount } from '../redux/CartSlice'
import { MdOutlineAccessTime } from "react-icons/md";
import { useAuth, useScreen } from '../authentication/context/screenContext'
import LoginPage from '../authentication/auth/LoginPage'

function CartSection({ closeBottomSheet }) {
    const { token } = useAuth()
    const cartItems = useSelector(selectCartItems);
    const dispatch = useDispatch()
    const totalAmount = useSelector(selectTotalAmount);
    const navigate = useNavigate()
    const { screenWidth } = useScreen()


    const offerList = [
        {
            id: 1,
            url: "https://cdn-icons-png.flaticon.com/512/6713/6713699.png",
            titile: "Cute nail provide discout any services ",
            des: "discount upto 10%  firsrt order ",
        }
    ]
    const quilistList = [
        {
            id: 1,
            url: "https://cdn-icons-png.flaticon.com/512/6713/6713699.png",
            titile: "Cute Nails Promiss ",
            des: [
                {
                    id: 1,
                    note: "Verify Professionals"
                },
                {
                    id: 2,
                    note: "Hassel Free booking"
                },
                {
                    id: 3,
                    note: "transparent pricing "
                },

                {
                    id: 4,
                    note: "service on schedule time sharp"
                },
            ]
        }
    ]


    const handleAddItem = (item) => {
        dispatch(addItem(item))

    };

    useEffect(() => {
        localStorage.setItem("cart", JSON.stringify(cartItems));
    }, [cartItems]);




    const handleDelItem = (item) => {
        dispatch(removeItem(item));
        const cart = JSON.parse(localStorage.getItem("cart")) || []; // Retrieve cart from localStorage
        const updatedCart = cart.filter(cartItem => cartItem._id !== item._id); // Filter out the item to be removed
        localStorage.setItem("cart", JSON.stringify(updatedCart))

    };
    const handleLogin = () => {
        notification.warning({
            placement: "topRight",
            message: "Please Login ",

        });
    }

    return (
        <div className='cart_setion'>
            <div className="cart_item">
                {cartItems.length != 0 ? 
                <Card size='small' title={`My Cart  (${cartItems.length})`} actions={[!token ? screenWidth < 800 ?   <Button  id='view_cart' onClick={handleLogin}><div className='view_cart_btn'><span>₹ {totalAmount}</span><span>Login</span></div></Button> :
                    <LoginPage closeBottomSheet={closeBottomSheet} totalAmount={totalAmount} /> : <Button type='primary' id='view_cart' onClick={() => navigate("/view-Cart")}><div className='view_cart_btn'><span>₹ {totalAmount}</span><span>View Cart</span></div></Button>]}>

                    <div className="carat_item_list">
                        <List
                            itemLayout="horizontal"
                            dataSource={cartItems}

                            renderItem={(item) => (
                                <List.Item
                                    actions={[<b style={{ color: "black" }}>₹ {item.price}</b>,]}
                                >
                                    <List.Item.Meta
                                        avatar={<Image src={item.serviceImage} width={50} height={50} style={{ borderRadius: "8px" }} />}
                                        title={item.serviceName}
                                        description={<Space>
                                            <div className='duration'>

                                                {/* <MdOutlineAccessTime className='duration_icon' /> */}
                                                <span style={{ textTransform: "lowercase" }}>{item.timeDuration} . {item.timeUnit}</span>
                                            </div>
                                            <div className="increment_btn">
                                                <Button.Group>
                                                    <Button onClick={() => handleDelItem(item)}> -</Button>
                                                    <Button> {item.unit}</Button>
                                                    <Button onClick={() => handleAddItem(item)}> +</Button>
                                                </Button.Group>
                                            </div>

                                        </Space>}
                                    />

                                </List.Item>
                            )}
                        />
                    </div>
                </Card> :
                    <Card>
                        <div className="empty_cart">
                            <img src={EmptyCart} />
                            <p>Your Cart is Empty !</p>
                        </div>
                    </Card>}
            </div>
            <div className="cart_offer">
                <Card>
                    <List

                        itemLayout="horizontal"
                        dataSource={offerList}
                        renderItem={(item) => (
                            <List.Item>
                                <List.Item.Meta
                                    avatar={<img src={item.url} style={{ width: "3rem" }} />}
                                    title={item.titile}
                                    description={item.des}
                                />

                            </List.Item>
                        )}
                    />
                </Card>
            </div>
            <div className="cart_offer">
                <Card>
                    <List
                        itemLayout="horizontal"
                        dataSource={quilistList}
                        renderItem={(items) => (
                            <List.Item>
                                <List.Item.Meta
                                    title={items.titile}
                                    description={<div className='note'>
                                        {items.des.map((item) => <ul key={item._id}>
                                            <li>{item.note}</li>
                                        </ul>)}
                                    </div>}
                                />
                            </List.Item>
                        )}
                    />
                </Card>
            </div>
        </div >
    )
}

export default CartSection