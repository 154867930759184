import React, { useEffect, useState } from "react";
import {
  Badge,
  Button,
  Card,
  Checkbox,
  Divider,
  Drawer,
  Form,
  Input,
  Modal,
  Skeleton,
  Space,
  message,
} from "antd";
import { MdOutlineHome, MdOutlineHomeWork, MdOutlineLocationOn } from "react-icons/md";
import "../style/Style.css";
import axios from "axios"
import clickIcon from "../assest/click-here-2.gif"
import { Col, Row } from "react-bootstrap"

import ShowMap from "./ShowMap";
import { AddUserAddress } from "../service/APIService";
import { useAuth } from "../authentication/context/screenContext";
import ShowActivePincode from "./ShowActivePincode";

const AddNewAddress = ({ setAddress, showSaveAddressList }) => {
  const [activePincode, setActivePincode] = useState(false)
  const [lat, setLat] = useState("")
  const [lng, setLng] = useState("")
  const [form] = Form.useForm();
  const [pinLocation, setPinLocation] = useState([])
  const [formLoader, setFormLoader] = useState(false)
  // const [typeSelect,setTypeSelect]=useState(false)
  const [addTypes, setAddsType] = useState("")
  const { token } = useAuth()

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };


  const { address } = pinLocation
  // const { suburb, state_district, state, postcode } = address
  console.log("address", address)




  useEffect(() => {
    if (lat != "" && lng != "") {

      async function getPinLocation() {
        setFormLoader(true)
        let result = await axios.get(
          `https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lng}&format=json`
        );
        console.log("pin location", result.data);
        setPinLocation(result.data)
        setFormLoader(false)
      }
      getPinLocation()
    }


  }, [lat, lng]);


  useEffect(() => {
    if (pinLocation != [])
      form.setFieldsValue({
        area: [address?.neighbourhood, address?.suburb, address?.state_district, address?.state, address?.postcode],
        pincode: address?.postcode,
      });
  }, [pinLocation,])

  const onFinish = async (value) => {
    const body = {

      latitude: lat,
      longitude: lng,
      area: value.area.join(),
      houseNo: value.houseNo,
      landmark: value.landmark,
      type: addTypes,
      pincode: value.pincode,
    }
    console.log("body", body)
    try {
      await AddUserAddress(token, body)
        .then((res) => {
          console.log("add address", res);
          if (res.status == 201) {
            message.success(res.data.message)
            setIsModalOpen(false);
            onReset()
            showSaveAddressList()
          } else if (res.status == 200) {
            message.error(res.data.message)
            setActivePincode(true)
          }

        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const onReset = () => {
    form.resetFields();
  };
  const onFinishFailed = (value) => {
    console.log(value)
    setAddress(true)
    setIsModalOpen(false);
  }
  const addressType = [
    {
      id: 1,
      title: "HOME",
      icon: <MdOutlineHome />
    },
    {
      id: 2,
      title: "WORK",
      icon: <MdOutlineHomeWork />

    },
    {
      id: 2,
      title: "OTHER",
      icon: <MdOutlineLocationOn />

    },
  ]

  const handleAddress = (add) => {
    setAddsType(add)
    console.log(add)
  }

  return (
    <>

      <div className="show_address">
        <Button type='primary' block onClick={showModal}>Add New Address</Button>
      </div>

      <Modal width={800} title="Add New Address" footer={false} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>

        <Row>
          <Col md={8}>
            <p id="note_map">Note : Please Drag Pin Icon On Your exact Location</p>

            <ShowMap setLat={setLat} setLng={setLng} lat={lat} lng={lng} />
            <div className="mt-3">
              {!activePincode ? null : <Space><img src={clickIcon} className="click_image"/><ShowActivePincode /></Space>}
            </div>
          </Col>
          <Col md={4}>
            <div className="add_form">
              {formLoader == true ? <Skeleton /> :
                <Form
                  form={form}
                  layout="vertical"
                  name="basic"
                  style={{
                    maxWidth: 600,
                  }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                >
                  <Form.Item
                    label="Area/Locality"
                    name="area"
                    rules={[
                      {
                        required: true,
                        message: "Please input your username!",
                      },
                    ]}
                  >
                    <Input placeholder="Enter Area" disabled />
                  </Form.Item>

                  <Col md={12}>

                    <Form.Item
                      label="House No/Plot NO"
                      name="houseNo"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter your houseNo!",
                        },
                      ]}
                    >
                      <Input placeholder="Enter  House No" />
                    </Form.Item>
                  </Col>
                  <Col md={12}>

                    <Form.Item
                      label="Landmark"
                      name="landmark"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter Lanndmark",
                        },
                      ]}
                    >
                      <Input placeholder="Enter Landmark " />
                    </Form.Item>
                  </Col>
                  <Col md={12}>

                    <Form.Item
                      label="Pincode"
                      name="pincode"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter Pincode",
                        },
                      ]}
                    >
                      <Input placeholder="Enter Pincode " />
                    </Form.Item>
                  </Col>

                  <Col md={12}>

                    <Form.Item
                      label="Save address as a"
                      name="addressType"


                    >
                      <div className="show_loc_type" >
                        {addressType.map((add) => (
                          <div className={` ${addTypes == add.title && "homeSelected"}`}>
                            <Card key={add.id} hoverable onClick={() => handleAddress(add.title)}>

                              <span >{add.icon}</span>
                              <p>{add.title}</p>

                            </Card>
                          </div>
                        ))}
                      </div>
                    </Form.Item>
                  </Col>


                  <Form.Item>
                    <div className="add_address_btn">
                      <Button block htmlType="submit" shape="round">
                        Submit
                      </Button>
                    </div>
                  </Form.Item>
                </Form>}
            </div>
          </Col>

        </Row>



      </Modal>
    </>
  );
};

export default AddNewAddress;