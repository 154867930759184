import React from 'react'
import './header.css'
import CallImage from "../../assest/call.png"
import EmailImg from "../../assest/gmail.png"
import { Space } from 'antd';

function SubHeader() {
    return (
        <div className='subheader'>
            <div className='subheader_content'>
                <div className='subDate_data '>
                    <img src={CallImage} />
                    <p> <a href="tel:+919319737920" style={{textDecoration:"none"}}>+919319737920</a></p>
                </div>
                {/* <div className="subDate_data">
                    <img src={EmailImg} />
                    <p>Info@cutenails.in
                    </p>
                </div> */}

            </div>
        </div>
    )
}

export default SubHeader