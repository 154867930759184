import React, { useState } from "react";
import EnquiryForm from "./EnquiryForm";
import { useScreen } from "../authentication/context/screenContext";
import { Button, Modal } from "antd";
import ContactButton from "./ContactButton";

const ContactUs = () => {
    const screenWidth = useScreen();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const showModal = () => setIsModalVisible(true);
    const handleCancel = () => setIsModalVisible(false);
  return (
    <section className="know-more-contaner">
      <div className="container">
        <div className="section-header">
          <h1> Contact Us</h1>
        </div>
        <div className="contact-us-main-conatiner">
            <div
             className="google-map-container"
            >
                 <iframe
              title="Google Map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d633.6947280198692!2d77.0541721!3d28.4410606!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1959952f4b39%3A0xc0f1d2906d6b8f2!2sOahfeo%20Workspaces%20-%20Backyard!5e0!3m2!1sen!2sin!4v1696076290550!5m2!1sen!2sin"
              width="100%"
              height="100%"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
            </div>
            <div
             className="enquary-form-container"
             >
                {screenWidth.screenWidth < 750 ? (
                  <Button type="primary" block onClick={showModal}  style={{ backgroundColor: 'var(--primary)', borderColor: 'var(--primary)' }}>
                    Enquire Now
                  </Button>
                ) : (
                  <EnquiryForm />
                )}
             </div>
        </div>
{/* <ContactButton/> */}
      </div>
      <Modal  visible={isModalVisible} onCancel={handleCancel} footer={null}>
          <EnquiryForm />
        </Modal>
    </section>
  );
};

export default ContactUs;
